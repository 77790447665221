import PropTypes from "prop-types";
import { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { authSuccess, logout } from "app/pages/Auth/AuthActions";
import submitter from "app/utils/redux-submitter";
import env from "app/utils/env";
import FacebookLogin from "app/pages/Auth/Facebook/FacebookLogin";
import { getStore } from "app/configureStore";
import { bindActionCreators } from "redux";
import {
	sendTagOnFacebookCancel,
	sendTagOnFacebookError,
	sendTagOnFacebookReAskDeclinedPermissions,
	sendTagOnFacebookReauthorize,
	sendTagOnFacebookSignin,
	sendTagOnFacebookSignup,
} from "app/utils/analytics";
import waitForFacebookApi from "app/utils/waitForFacebookApi";
import { HTTP_STATUS_CODES } from "app/constants";
import { isServerSide } from "app/utils/utils";

const FACEBOOK_CONNECT_URL = "/auth/facebook";
const FACEBOOK_STATUS = {
	CONNECTED: "connected",
	NOT_AUTHORIZED: "not_authorized",
	UNKNOWN: "unknown",
};

const FacebookContainer = ({ facebookLogin, signup = false }) => {
	const [isLoading, setLoading] = useState(false);

	const isFacebookSDKReady = waitForFacebookApi();

	const reAuthorizeFacebook = () => {
		window.FB.login(
			response => {
				setLoading(false);
				if (response.authResponse) {
					facebookLogin(response.authResponse);
				} else {
					// User cancelled login or did not fully authorize.
					sendTagOnFacebookCancel();
				}
			},
			{ scope: "email", auth_type: "reauthorize" }
		);
	};

	// @see https://developers.facebook.com/docs/facebook-login/handling-declined-permissions
	const reAskDeclinedPermissions = () => {
		window.FB.login(
			response => {
				setLoading(false);
				if (response.authResponse) {
					facebookLogin(response.authResponse);
				} else {
					// User cancelled login or did not fully authorize.
					sendTagOnFacebookCancel();
				}
			},
			{
				scope: "email",
				return_scopes: true,
				auth_type: "rerequest",
			}
		);
	};

	const loginWithFacebook = () => {
		window.FB.login(
			response => {
				setLoading(false);
				if (response.authResponse) {
					facebookLogin(response.authResponse);
				} else {
					// User cancelled login or did not fully authorize.
					sendTagOnFacebookCancel();
				}
			},
			{
				scope: "email",
				return_scopes: true, // to have grantedScopes in the authResponse
			}
		);
	};

	const checkForFacebookPermissions = response => {
		return !response?.authResponse?.grantedScopes?.includes("email");
	};

	const checkForFacebookTokenExpiration = response => {
		const authResponse = response && response.authResponse;
		const facebookDataAccessExpiration = authResponse.data_access_expiration_time * 1000;
		const today = Date.now();
		const isDataAccessExpired = today > facebookDataAccessExpiration;
		return isDataAccessExpired;
	};

	const handleFacebookStatusChange = response => {
		if (response.status === FACEBOOK_STATUS.CONNECTED) {
			// The user is logged in and has authenticated your
			// app, and response.authResponse supplies
			// the user's ID, a valid access token, a signed
			// request, and the time the access token
			// and signed request each expire.
			if (checkForFacebookPermissions(response)) {
				reAskDeclinedPermissions();
				sendTagOnFacebookReAskDeclinedPermissions();
			} else if (checkForFacebookTokenExpiration(response)) {
				reAuthorizeFacebook();
				sendTagOnFacebookReauthorize();
			} else {
				setLoading(false);
				facebookLogin(response.authResponse);
			}
		} else {
			// The user isn't logged in to Facebook. You can launch a
			// login dialog with a user gesture, but the user may have
			// to log in to Facebook before authorizing your application.
			// Facebook token may had expired here.
			loginWithFacebook();
		}
	};

	const handleFacebookAuth = () => {
		setLoading(true);
		// @see https://developers.facebook.com/docs/facebook-login/web
		if (!isServerSide && window.FB) {
			window.FB.getLoginStatus(function(response) {
				handleFacebookStatusChange(response);
			});
		}
	};

	return (
		<FacebookLogin
			isSignup={signup}
			isLoading={isLoading}
			onClick={handleFacebookAuth}
			isDisabled={!isFacebookSDKReady}
		/>
	);
};

FacebookContainer.propTypes = {
	facebookLogin: PropTypes.func,
	signup: PropTypes.bool,
};

const promiseOnData = data => {
	const store = getStore().getState();

	return axios.post(
		env("USER_AUTH_API_URL") + FACEBOOK_CONNECT_URL,
		{
			...data,
			partner: store.partner,
			source: store.marketing.source,
		},
		{
			headers: {
				"Content-type": "text/plain",
			},
		}
	);
};

const mapDispatchToProps = (dispatch, { onSuccess, onError, ...props }) => {
	const facebookLogin = data =>
		submitter(
			promiseOnData,
			success => {
				dispatch(logout);
				dispatch(authSuccess(success));
				if (success.data.signup) {
					sendTagOnFacebookSignup();
				} else {
					sendTagOnFacebookSignin();
				}

				if (onSuccess && typeof onSuccess === "function") {
					onSuccess(success);
				}
			},
			error => {
				sendTagOnFacebookError(error.data.message);

				if (error.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
					window.FB.login(
						response => {
							if (response.authResponse) {
								facebookLogin(response.authResponse);
							} else {
								facebookLogin({ status: response.status });
							}
						},
						{
							scope: "email",
							return_scopes: true,
						}
					);
				} else if (error.status === HTTP_STATUS_CODES.FORBIDDEN) {
					onError(error);
				} else if (onError && typeof onError === "function") {
					onError(error);
				}
			}
		)(data, dispatch, props);

	return {
		facebookLogin,
		...bindActionCreators(facebookLogin, dispatch),
	};
};

export default connect(
	null,
	mapDispatchToProps
)(FacebookContainer);
