import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "axios";
import {
	FETCH_FAQ_ARTICLE,
	FETCH_FAQ_CATEGORIES,
	FETCH_PARTNER_DATA,
} from "app/reducers/partnerActionTypes";
import { FETCH_BRAND_DATA } from "app/reducers/brandActionTypes";

export const getBrandData = () => {
	const state = getStore().getState();
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_BRAND_DATA,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/resources/brand.json`),
	};
};

export const getPartnerData = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_PARTNER_DATA,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/resources/${shop}/shop.json`),
	};
};

export const getFaqCategories = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_FAQ_CATEGORIES,
		promise: axios.get(`${endpoint}/${state.productsVisibility}/resources/${shop}/faq.json`),
	};
};

export const getFaqArticle = articleId => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_FAQ_ARTICLE,
		promise: axios.get(
			`${endpoint}/${state.productsVisibility}/resources/${shop}/${articleId}.json`
		),
	};
};
