import {
	FETCH_COUPONS_SUCCESS,
	HIDE_CREDITNOTES_NOTIFICATION,
	HIDE_CREDITS_NOTIFICATION,
} from "app/pages/Account/MyCoupons/couponActionTypes";
import get from "lodash/get";
import { LOGOUT } from "app/actionTypes";

const defaultState = {
	sponsorship: {},
	generic: {},
	creditNote: {},
	travelback: {},
	showCreditsNotification: true,
	showCreditNotesNotification: true,
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case HIDE_CREDITNOTES_NOTIFICATION: {
			return {
				...state,
				showCreditNotesNotification: false,
			};
		}
		case HIDE_CREDITS_NOTIFICATION: {
			return {
				...state,
				showCreditsNotification: false,
			};
		}
		case FETCH_COUPONS_SUCCESS: {
			const sponsorship = get(action, "res.data.credit.sponsorship", {});
			const generic = get(action, "res.data.credit.generic", {});
			const travelback = get(action, "res.data.credit.travelback", {});
			const creditNote = get(action, "res.data.creditNote", {});

			return {
				...state,
				sponsorship,
				generic,
				travelback,
				creditNote,
			};
		}
		case LOGOUT:
			return defaultState;
		default:
			return state;
	}
};
