const INITIAL_STATE = {
	isInApp: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		default: {
			return state;
		}
	}
};
