import { createSelector } from "reselect";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import {
	CREDIT_TYPES,
	PAYMENT_COUPON_TYPE,
	SHOW_CREDIT_NOTES_EXPIRATION_MAX_YEAR,
	SPONSORSHIP_SOURCES,
} from "app/constants";
import intervalToDuration from "date-fns/intervalToDuration";

const getCoupons = state => state.coupons;
const getUsableCoupons = state => state.usableCoupons;
const getPromotionValue = state => get(state, "promotion.promotionValue", 0);
const getCouponType = state => state.booking.coupon;

export const getActiveGenericCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.current", []);
	}
);

export const getHistoryGenericCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.history", []);
	}
);

export const getActiveTravelbackCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.current", []);
	}
);

export const getHistoryTravelbackCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.history", []);
	}
);

export const getActiveSponsorshipCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.current", []);
	}
);

export const getHistorySponsorshipCredits = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.history", []);
	}
);

export const getSortedCurrentSponsorshipCredits = createSelector(
	[getActiveSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getCurrentSponsorshipAcceptedCredits = createSelector(
	[getActiveSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getSortedHistorySponsorshipCredits = createSelector(
	[getHistorySponsorshipCredits],
	(historySponsorships = []) => {
		return (
			groupBy(
				historySponsorships.filter(
					historySponsorship =>
						historySponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getHistorySponsorshipAcceptedCredits = createSelector(
	[getHistorySponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getAvailableCreditsTotal = createSelector(
	[getActiveGenericCredits, getActiveTravelbackCredits, getActiveSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		const genericCreditsTotal =
			genericCredits.length > 0
				? genericCredits.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return { balance: (accCredit.balance || 0) + (currentCredit.balance || 0) };
				  })
				: { balance: 0 };

		const travelBackCreditsTotal =
			travelBackCredits.length > 0
				? travelBackCredits.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return { balance: (accCredit.balance || 0) + (currentCredit.balance || 0) };
				  })
				: { balance: 0 };

		const sponsorshipCreditsTotal =
			sponsorshipCredits.length > 0
				? sponsorshipCredits.reduce((accCredit, currentCredit = { balance: 0 }) => {
						return { balance: (accCredit.balance || 0) + (currentCredit.balance || 0) };
				  })
				: { balance: 0 };

		return (
			genericCreditsTotal.balance +
			travelBackCreditsTotal.balance +
			sponsorshipCreditsTotal.balance
		);
	}
);

export const getActiveCreditNotes = createSelector(
	[getCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.current", []);
	}
);

export const getHistoryCreditNotes = createSelector(
	[getCoupons],
	(coupons = {}) => {
		const creditNotes = coupons?.creditNote?.history || [];
		const today = Date.now();

		return creditNotes.filter(creditNote => {
			if (creditNote.maxUseDate) {
				const duration = intervalToDuration({
					start: today,
					end: creditNote.maxUseDate,
				});

				return duration.years <= SHOW_CREDIT_NOTES_EXPIRATION_MAX_YEAR;
			}

			return false;
		});
	}
);

export const hasAnyCreditNotes = createSelector(
	[getActiveCreditNotes, getHistoryCreditNotes],
	(activeCreditNotes = [], historyCreditNotes = []) => {
		return activeCreditNotes.length > 0 || historyCreditNotes.length > 0;
	}
);

export const getAvailableCreditNotesTotal = createSelector(
	[getActiveCreditNotes],
	(creditNotes = []) => {
		const creditNoteTotal =
			creditNotes.length > 0
				? creditNotes.reduce((accCreditNote, currentCreditNote = { balance: 0 }) => {
						return {
							balance:
								(accCreditNote.balance || 0) + (currentCreditNote.balance || 0),
						};
				  })
				: { balance: 0 };

		return creditNoteTotal.balance;
	}
);

/**
 * USABLE CREDITS
 */

export const getUsableGenericCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.usable", []);
	}
);

export const getUnusableGenericCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "generic.unusable", []);
	}
);

export const getUsableTravelbackCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.usable", []);
	}
);

export const getUnusableTravelbackCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "travelback.unusable", []);
	}
);

export const getUsableSponsorshipCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.usable", []);
	}
);

export const getUnusableSponsorshipCredits = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "sponsorship.unusable", []);
	}
);

export const getSortedUsableSponsorshipCredits = createSelector(
	[getUsableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUsableSponsorshipAcceptedCredits = createSelector(
	[getUsableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getSortedUnusableSponsorshipCredits = createSelector(
	[getUnusableSponsorshipCredits],
	(unusableSponsorships = []) => {
		return (
			groupBy(
				unusableSponsorships.filter(
					unusableSponsorship =>
						unusableSponsorship.source !== SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUnusableSponsorshipAcceptedCredits = createSelector(
	[getUnusableSponsorshipCredits],
	(activeSponsorships = []) => {
		return (
			groupBy(
				activeSponsorships.filter(
					activeSponsorship =>
						activeSponsorship.source === SPONSORSHIP_SOURCES.SPONSORSHIP_ACCEPTED
				),
				"friendEmail"
			) || {}
		);
	}
);

export const getUsableCreditsTotal = createSelector(
	[getUsableGenericCredits, getUsableTravelbackCredits, getUsableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		return calculateUsableCreditsTotal(genericCredits, travelBackCredits, sponsorshipCredits);
	}
);

export const calculateUsableCreditsTotal = (
	genericCredits = [],
	travelBackCredits = [],
	sponsorshipCredits = []
) => {
	const genericCreditsTotal =
		genericCredits.length > 0
			? genericCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	const travelBackCreditsTotal =
		travelBackCredits.length > 0
			? travelBackCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	const sponsorshipCreditsTotal =
		sponsorshipCredits.length > 0
			? sponsorshipCredits.reduce((accCredit, usableCredit = { usableAmount: 0 }) => {
					return {
						usableAmount:
							(accCredit.usableAmount || 0) + (usableCredit.usableAmount || 0),
					};
			  })
			: { usableAmount: 0 };

	return (
		genericCreditsTotal.usableAmount +
		travelBackCreditsTotal.usableAmount +
		sponsorshipCreditsTotal.usableAmount
	);
};

export const getUsableCreditNotes = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.usable", []);
	}
);

export const getUnusableCreditNotes = createSelector(
	[getUsableCoupons],
	(coupons = {}) => {
		return get(coupons, "creditNote.unusable", []);
	}
);

export const getUsableCreditNotesTotal = createSelector(
	[getUsableCreditNotes],
	(creditNotes = []) => {
		const creditNoteTotal =
			creditNotes.length > 0
				? creditNotes.reduce((accCreditNote, usableCredit = { usableAmount: 0 }) => {
						return {
							usableAmount:
								(accCreditNote.usableAmount || 0) +
								(usableCredit.usableAmount || 0),
						};
				  })
				: { usableAmount: 0 };

		return creditNoteTotal.usableAmount;
	}
);

export const getOrderedUsableCredits = createSelector(
	[getUsableGenericCredits, getUsableTravelbackCredits, getUsableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		let totallyUsableCredits = [];
		let partiallyUsableCredits = [];

		sponsorshipCredits.forEach(sponsorshipCredit => {
			if (sponsorshipCredit.usableAmount === sponsorshipCredit.balance) {
				totallyUsableCredits.push(sponsorshipCredit);
			} else if (sponsorshipCredit.usableAmount !== sponsorshipCredit.balance) {
				partiallyUsableCredits.push(sponsorshipCredit);
			}
		});

		travelBackCredits.forEach(travelBackCredit => {
			if (travelBackCredit.usableAmount === travelBackCredit.balance) {
				totallyUsableCredits.push(travelBackCredit);
			} else if (travelBackCredit.usableAmount !== travelBackCredit.balance) {
				partiallyUsableCredits.push(travelBackCredit);
			}
		});

		genericCredits.forEach(genericCredit => {
			if (genericCredit.usableAmount === genericCredit.balance) {
				totallyUsableCredits.push(genericCredit);
			} else if (genericCredit.usableAmount !== genericCredit.balance) {
				partiallyUsableCredits.push(genericCredit);
			}
		});

		totallyUsableCredits = orderBy(
			totallyUsableCredits,
			totallyUsableCredit => {
				return totallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		partiallyUsableCredits = orderBy(
			partiallyUsableCredits,
			partiallyUsableCredit => {
				return partiallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		return totallyUsableCredits.concat(partiallyUsableCredits);
	}
);

export const getOrderedUnusableCredits = createSelector(
	[getUnusableGenericCredits, getUnusableTravelbackCredits, getUnusableSponsorshipCredits],
	(genericCredits = [], travelBackCredits = [], sponsorshipCredits = []) => {
		let applicableUnusableCredits = [];
		let unApplicableUnusableCredits = [];

		sponsorshipCredits.forEach(sponsorshipCredit => {
			if (sponsorshipCredit.isApplicable) {
				applicableUnusableCredits.push(sponsorshipCredit);
			} else {
				unApplicableUnusableCredits.push(sponsorshipCredit);
			}
		});

		travelBackCredits.forEach(travelBackCredit => {
			if (travelBackCredit.isApplicable) {
				applicableUnusableCredits.push(travelBackCredit);
			} else {
				unApplicableUnusableCredits.push(travelBackCredit);
			}
		});

		genericCredits.forEach(genericCredit => {
			if (genericCredit.isApplicable) {
				applicableUnusableCredits.push(genericCredit);
			} else {
				unApplicableUnusableCredits.push(genericCredit);
			}
		});

		applicableUnusableCredits = orderBy(
			applicableUnusableCredits,
			applicableUnusableCredit => {
				return applicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		unApplicableUnusableCredits = orderBy(
			unApplicableUnusableCredits,
			unApplicableUnusableCredit => {
				return unApplicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		return applicableUnusableCredits.concat(unApplicableUnusableCredits);
	}
);

export const getOnlyNonApplicableCredits = createSelector(
	[getOrderedUnusableCredits],
	unusableCredits => {
		return unusableCredits.filter(unusableCredit => {
			return !unusableCredit.isApplicable;
		});
	}
);

export const getOnlyNonApplicableCreditNotes = createSelector(
	[getUnusableCreditNotes],
	unusableCreditNotes => {
		return unusableCreditNotes.filter(unusableCreditNote => {
			return !unusableCreditNote.isApplicable;
		});
	}
);

export const getOrderedUsableCreditNotes = createSelector(
	[getUsableCreditNotes],
	(usableCreditNotes = []) => {
		let totallyUsableCredits = [];
		let partiallyUsableCredits = [];

		usableCreditNotes.forEach(usableCreditNote => {
			if (usableCreditNote.usableAmount === usableCreditNote.balance) {
				totallyUsableCredits.push(usableCreditNote);
			} else if (usableCreditNote.usableAmount !== usableCreditNote.balance) {
				partiallyUsableCredits.push(usableCreditNote);
			}
		});

		totallyUsableCredits = orderBy(
			totallyUsableCredits,
			totallyUsableCredit => {
				return totallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		partiallyUsableCredits = orderBy(
			partiallyUsableCredits,
			partiallyUsableCredit => {
				return partiallyUsableCredit.maxUseDate;
			},
			["asc"]
		);

		return totallyUsableCredits.concat(partiallyUsableCredits);
	}
);

export const getOrderedUnusableCreditNotes = createSelector(
	[getUnusableCreditNotes],
	(unusableCreditNotes = []) => {
		let applicableUnusableCredits = [];
		let unApplicableUnusableCredits = [];

		unusableCreditNotes.forEach(unusableCreditNote => {
			if (unusableCreditNote.isApplicable) {
				applicableUnusableCredits.push(unusableCreditNote);
			} else {
				unApplicableUnusableCredits.push(unusableCreditNote);
			}
		});

		applicableUnusableCredits = orderBy(
			applicableUnusableCredits,
			applicableUnusableCredit => {
				return applicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		unApplicableUnusableCredits = orderBy(
			unApplicableUnusableCredits,
			unApplicableUnusableCredit => {
				return unApplicableUnusableCredit.maxUseDate;
			},
			["asc"]
		);

		return applicableUnusableCredits.concat(unApplicableUnusableCredits);
	}
);

export const getPromotionAmount = createSelector(
	[getCouponType, getPromotionValue],
	(couponType, promotionValue = 0) => {
		return couponType === PAYMENT_COUPON_TYPE.PROMOCODE ? promotionValue : 0;
	}
);

export const getUsableCreditAmount = createSelector(
	[getCouponType, getUsableCreditsTotal],
	(couponType, creditsValue = 0) => {
		return couponType === PAYMENT_COUPON_TYPE.CREDIT ? creditsValue : 0;
	}
);

export const getAllCouponsTotal = createSelector(
	[getUsableCreditAmount, getUsableCreditNotesTotal, getPromotionAmount],
	(usableCreditsTotal = 0, usableCreditNotesTotal = 0, promotionValue = 0) => {
		return promotionValue + usableCreditsTotal + usableCreditNotesTotal;
	}
);

export const getVoucherTypesForAnalytics = createSelector(
	[getActiveGenericCredits, getActiveTravelbackCredits, getActiveSponsorshipCredits],
	(genericCredits = 0, travelBackCredits = 0, sponsorshipCredits = 0) => {
		const creditTypes = [];

		if (sponsorshipCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.SPONSORSHIP);
		}

		if (travelBackCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.TRAVELBACK);
		}

		if (genericCredits.length > 0) {
			creditTypes.push(CREDIT_TYPES.GENERIC);
		}

		return creditTypes.length > 0 ? creditTypes.join(",") : "";
	}
);
