import { isCreditCardAmex } from "app/utils/creditCardUtils";
import { CB_NUMBER_MAX_LENGTH } from "app/constants";

export const normalizeCreditCardNumber = value => {
	if (!value) {
		return value;
	}

	const creditCarNumberValue = String(value);

	const onlyNums = creditCarNumberValue.replace(/[^\d]/g, "");

	return onlyNums.slice(0, CB_NUMBER_MAX_LENGTH);
};

export const normalizeCVC = (value, previousValues, allValues) => {
	if (!value) {
		return value;
	}

	const paymentCardNumber = allValues && allValues.payment && allValues.payment.card.number;
	const cvcLenghtLimit = isCreditCardAmex(paymentCardNumber) ? 4 : 3;

	const valueString = String(value);

	return valueString.length > cvcLenghtLimit ? valueString.substring(0, cvcLenghtLimit) : value;
};

export const minZero = value => {
	if (!value) {
		return value;
	}

	return value < 0 ? 0 : value;
};

export const castToNumber = value => {
	if (!value) {
		return value;
	}

	return Number(value);
};
