const getSeparator = url => {
	const match = url.match(/\/image\/([^/]+)\//);

	return match ? match[1] : "";
};

export const HANDLED_CLOUDINARY_IMAGE_TYPES = ["fetch", "upload"];

const isAllowedSeperator = separator => HANDLED_CLOUDINARY_IMAGE_TYPES.includes(separator);
const isCloudinaryUrl = url => url && url.startsWith("https://res.cloudinary.com/");
export const getCloudinaryFetchUrl = url =>
	url && typeof url === "string" ? `https://res.cloudinary.com/dylzil5ck/image/fetch/${url}` : "";

export const addOptionsToCloundinaryURL = (
	url = "",
	cloudinaryOptions = [],
	{
		quality = "",
	} = {} /** https://cloudinary.com/blog/the_holy_grail_of_image_optimization_or_balancing_visual_quality_and_file_size */
) => {
	if (!isCloudinaryUrl(url)) {
		return url;
	}

	const isGif = url.indexOf(".gif") !== -1;
	const isSVG = url.indexOf(".svg") !== -1;
	const qualityOption = quality || "q_auto";
	let cloudinaryCommonConfig = [isGif ? "q_60" : qualityOption];

	if (!isSVG) {
		cloudinaryCommonConfig = cloudinaryCommonConfig.concat(["f_auto", "fl_lossy"]);
	}

	if (url) {
		if (cloudinaryOptions.length > 0) {
			if (url.indexOf("t_pfs") === -1) {
				const separator = getSeparator(url);

				if (!isAllowedSeperator(separator)) {
					return url;
				}

				const imageUrl = url.split(`/${separator}/`);

				return `${imageUrl[0]}/${separator}/${[
					...cloudinaryOptions,
					...cloudinaryCommonConfig,
				]}/${imageUrl[1]}`;
			}

			return url.replace(
				"t_pfs",
				`t_pfs,${[...cloudinaryOptions, ...cloudinaryCommonConfig]}`
			);
		}
		return url;
	}

	return "";
};
