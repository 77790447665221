import { connect } from "react-redux";
import SimpleHeader from "app/pages/Header/SimpleHeader/SimpleHeader";
import { matchPath, withRouter } from "react-router-dom";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { hasCustomDynamicHeader } from "app/reducers/brandSelector";
import { parseQueries } from "app/utils/routerUtils";
import { checkIfComingFromRemarketingEmail } from "app/utils/urlDataExtractor";

const mapStateToProps = (state, props) => {
	const currentPathname = props.location.pathname;

	const {
		utm_source,
		utm_medium,
		productUri,
		redirectToProductPage: isComingFromNavlogs,
	} = parseQueries(props.location.search);

	const isComingFromRemarketingEmail = checkIfComingFromRemarketingEmail(utm_source, utm_medium);

	const isQuotationPage = matchPath(currentPathname, {
		path: "/booking/quote",
	});

	const isPaiementPage = matchPath(currentPathname, {
		path: "/booking/payment",
	});

	const isConfirmationPage = matchPath(currentPathname, {
		path: "/booking/confirmation",
	});

	const isAuthPage =
		matchPath(currentPathname, {
			path: "/auth",
		}) ||
		matchPath(currentPathname, {
			path: "/booking/auth",
		});

	const isLandingProductPage = props.match.path === "/product/:productUri";

	const contextCallCenter = getContextCallCenter({
		isPaymentPage: isPaiementPage,
		isQuotationPage,
		isConfirmationPage,
	})(state);

	const brand = state.brand.code;

	return {
		previousUrl: `/${productUri}`,
		brand,
		operatorEmail: state.auth.operatorEmail,
		hideBackButton:
			isAuthPage ||
			isLandingProductPage ||
			isConfirmationPage ||
			hasCustomDynamicHeader(state),
		...contextCallCenter,
		isInApp: state.navigation.isInApp,
		// we want to go back to product page when coming from marketing email and navlogs
		forceBrowserBack: !isComingFromRemarketingEmail && !isComingFromNavlogs,
	};
};

export default withRouter(connect(mapStateToProps)(SimpleHeader));
