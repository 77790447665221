import { resendConfirmationMail } from "app/pages/Auth/AuthActions";
import { FormattedMessage, injectIntl } from "react-intl";
import { Route } from "react-router-dom";
import Button from "app/pages/.shared/form/Button";
import Separator from "app/pages/.shared/Separator";
import RelativeLink from "app/pages/.shared/RelativeLink";
import getHistory from "app/configureHistory";
import { Component } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import "./RegisterMessage.scss";

class RegisterMessageComponent extends Component {
	constructor(props) {
		super(props);
		this.resendConfirmationMail = this.resendConfirmationMail.bind(this);
	}

	resendConfirmationMail() {
		const { match, email, shop } = this.props;
		resendConfirmationMail({ match, email, shop });
	}

	render() {
		const { match, email, partner, helpContactUrl, intl } = this.props;
		const today = new Date();
		const teasingEndDate = new Date(get(partner, "teasingEndDate"));
		const isTeasing = today < new Date(teasingEndDate);

		return (
			<>
				<Route
					path={`${match.url}/registration-succeeded`}
					render={() => (
						<div className="register-message">
							<div className="register-message__title">
								<FormattedMessage id="auth.register.success.title" />
							</div>
							<div className="register-message__body">
								{isTeasing ? (
									<>
										<FormattedMessage
											id="auth.register.success.teasing.message.1"
											values={{
												email: (
													<span className="register-message__highlight">
														{email}
													</span>
												),
											}}
										/>
										<div>
											<FormattedMessage
												id="auth.register.success.teasing.message.2"
												values={{
													span: chunks => (
														<span className="register-message__highlight">
															{chunks}
														</span>
													),
												}}
											/>
										</div>
									</>
								) : (
									<>
										<FormattedMessage
											id="auth.register.success.message.1"
											values={{
												email: (
													<span className="register-message__highlight">
														{email}
													</span>
												),
											}}
										/>
										<div>
											<FormattedMessage id="auth.register.success.message.2" />
										</div>
									</>
								)}
							</div>
							<Separator design="middle" />

							<div className="register-message__title">
								<FormattedMessage id="auth.register.success.resend.title" />
							</div>
							<div className="register-message__body register-message__body--left">
								<div>
									<FormattedMessage id="auth.register.success.resend.list.item.1" />
								</div>
								<div>
									<FormattedMessage
										id="auth.register.success.resend.list.item.2"
										values={{
											url: (
												<RelativeLink
													to={{
														pathname: isTeasing
															? "/teasing"
															: "/home/signup",
													}}
													className="register-message__link"
												>
													<FormattedMessage id="auth.register.change.here" />
												</RelativeLink>
											),
										}}
									/>
								</div>
								<div>
									<FormattedMessage
										id="auth.register.success.resend.list.item.3"
										values={{
											callCenter: (
												<a
													className="register-message__link"
													href={helpContactUrl}
													target="_blank"
													rel="noopener noreferrer"
												>
													<FormattedMessage id="auth.register.contact.callcenter" />
												</a>
											),
										}}
									/>
								</div>
							</div>
						</div>
					)}
				/>

				<Route
					path={`${match.url}/registration-confirmed`}
					render={() => (
						<div className="register-message">
							<div className="register-message__title">
								<FormattedMessage id="auth.register.confirmed.success.title" />
							</div>
							<div className="register-message__body">
								<FormattedMessage id="auth.register.confirmed.success.message.1" />
								<div>
									<FormattedMessage
										id="auth.register.confirmed.success.message.2"
										values={{
											date: (
												<span className="register-message__highlight">
													{intl.formatDate(teasingEndDate, {
														year: "numeric",
														month: "long",
														day: "numeric",
													})}
												</span>
											),
										}}
									/>
								</div>
							</div>
						</div>
					)}
				/>
				<Route
					path={`${match.url}/registration-not-confirmed`}
					render={() => (
						<div className="register-message">
							<div className="register-message__body">
								<FormattedMessage
									id="auth.register.not.confirmed.body"
									values={{
										email: (
											<span className="register-message__highlight">
												{email}
											</span>
										),
									}}
								/>
							</div>
							<Button variant="primary" onClick={this.resendConfirmationMail}>
								<FormattedMessage id="auth.register.not.confirmed.cta.label" />
							</Button>
						</div>
					)}
				/>

				<Route
					path={`${match.url}/resend-confirmed`}
					render={() => {
						return (
							<div className="register-message">
								<div className="register-message__body">
									<FormattedMessage id="auth.register.confirmation.mail.resend.message" />
								</div>

								<Button
									variant="inverted"
									onClick={() =>
										getHistory().push(isTeasing ? "/teasing" : "/home/signup")
									}
								>
									<FormattedMessage id="general.back" />
								</Button>
							</div>
						);
					}}
				/>
				<Route
					path={`${match.url}/user-not-found`}
					render={() => {
						return (
							<div className="register-message">
								<div className="register-message__body">
									<FormattedMessage id="error.user.not.identified" />
								</div>
								<Button
									variant="inverted"
									onClick={() =>
										getHistory().push(isTeasing ? "/teasing" : "/home/signup")
									}
								>
									<FormattedMessage id="general.back" />
								</Button>
							</div>
						);
					}}
				/>
			</>
		);
	}
}

RegisterMessageComponent.propTypes = {
	intl: PropTypes.object,
	match: PropTypes.object,
	email: PropTypes.string,
	partner: PropTypes.object,
	helpContactUrl: PropTypes.string,
	shop: PropTypes.string,
};

export const RegisterMessage = injectIntl(RegisterMessageComponent);
