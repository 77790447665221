import { createSelector } from "reselect";
import { BRANDS } from "app/constants";
import get from "lodash/get";

const getBrand = state => state.brand;
const getProfile = state => state.profile;

export const shouldShowFrequencyModal = createSelector(
	[getBrand, getProfile],
	(brand = {}, profile = {}) => {
		return (
			brand.code === BRANDS.AX &&
			get(profile, "newsletterPreference.shouldDisplayFrequencyModal") === true
		);
	}
);
