import axios from "axios/index";
import env from "app/utils/env";
import { getCredentials } from "app/utils/auth";
import {
	FETCH_COUPONS,
	FETCH_USABLE_COUPONS,
	FETCH_USABLE_CREDIT_NOTES,
	HIDE_CREDITNOTES_NOTIFICATION,
	HIDE_CREDITS_NOTIFICATION,
} from "app/pages/Account/MyCoupons/couponActionTypes";

export const fetchCoupons = () => {
	const { token } = getCredentials();
	return {
		type: FETCH_COUPONS,
		promise: axios.get(env("SPONSORSHIP_API_URL") + "/list", {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const fetchUsableCoupons = ({
	connectivitySessionCode,
	isPromoCode,
	promotionCode,
	insuranceCode,
}) => {
	const { token } = getCredentials();
	const payload = {
		code: connectivitySessionCode,
	};

	if (isPromoCode && !promotionCode) {
		payload.promoCode = "";
	} else if (isPromoCode && promotionCode) {
		payload.promoCode = promotionCode;
	}

	if (insuranceCode) {
		payload.insurance = insuranceCode;
	}

	return {
		type: FETCH_USABLE_COUPONS,
		promise: axios.post(env("QUOTE_API_URL") + "/usableCredits", payload, {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const fetchUsableCreditNotes = ({
	connectivitySessionCode,
	isPromoCode,
	promotionCode,
	insuranceCode,
}) => {
	const { token } = getCredentials();
	const payload = {
		code: connectivitySessionCode,
	};

	if (isPromoCode && !promotionCode) {
		payload.promoCode = "";
	} else if (isPromoCode && promotionCode) {
		payload.promoCode = promotionCode;
	}

	if (insuranceCode) {
		payload.insurance = insuranceCode;
	}

	return {
		type: FETCH_USABLE_CREDIT_NOTES,
		promise: axios.post(env("QUOTE_API_URL") + "/usableCredits", payload, {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
		}),
	};
};

export const hideCreditNotification = () => {
	return {
		type: HIDE_CREDITS_NOTIFICATION,
	};
};

export const hideCreditNoteNotification = () => {
	return {
		type: HIDE_CREDITNOTES_NOTIFICATION,
	};
};
