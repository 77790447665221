/* eslint-disable no-unused-vars */
/**
 * TODO: remove once upgraded to redux-form v6, which makes this redundant
 * https://github.com/erikras/redux-form/issues/1441#issuecomment-236966387
 */
export const reduxFormDomOnlyProps = ({
	initialValue,
	autofill,
	onUpdate,
	valid,
	invalid,
	dirty,
	pristine,
	active,
	touched,
	visited,
	autofilled,
	error,
	...domProps
}) => domProps;
