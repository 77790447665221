import { createSelector } from "reselect";
import { BRANDS, STRATEGY } from "app/constants";
import { getCurrentShop } from "app/reducers/shopSelector";
import { getShopData } from "app/utils/utils";

const getStrategy = state => state.partner.strategy;

export const getBrand = state => state.brand.code;
export const getShopsList = state => state.brand?.shops;

export const isWhiteLabel = createSelector(
	[getStrategy],
	strategy => {
		return (
			strategy === STRATEGY.SIGNUP_FIRST ||
			strategy === STRATEGY.NAVIGATION_FIRST ||
			strategy === STRATEGY.PRODUCT_FIRST
		);
	}
);

export const hasCustomDynamicHeader = createSelector(
	[getBrand],
	brand => {
		return brand === BRANDS.CD || brand === BRANDS.SP;
	}
);

export const getDefaultPartnerCodeForShop = createSelector(
	[getShopsList, getCurrentShop],
	(shops = [], shop) => {
		const shopData = getShopData(shops, shop);
		const [lang] = shop.split("-");
		const defaultMarketingCodeData = shopData?.marketingCodes.find(marketingCode => {
			return marketingCode.isDefaultMarketingCodeForShop && marketingCode.locale === lang;
		});
		return defaultMarketingCodeData?.code;
	}
);
