import PropTypes from "prop-types";
import { Component } from "react";
import axios from "axios";
import { Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { untouch } from "redux-form";
import Signup from "app/pages/Auth/Signup/Signup.jsx";
import {
	sendTagOnSponsorshipSubscribeFailed,
	sendTagOnSponsorshipSubscribeSuccess,
} from "app/utils/analytics";
import env from "app/utils/env";
import { brandPropTypes, partnerShape } from "app/utils/propTypes";
import { logout, registerPromise, signupPromise } from "app/pages/Auth/AuthActions";
import { injectIntl } from "react-intl";
import get from "lodash/get";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { RegisterMessage } from "./RegisterMessage";
import { getParentPath } from "app/utils/routerUtils";
import { BRANDS } from "app/constants";

class SignupContainer extends Component {
	render() {
		const {
			cnilDocumentName,
			confidentialiteDocumentName,
			headline,
			footer,
			showTopFacebook = true,
			showBottomFacebook = false,
			onAuthSuccess,
			onToogleSigninForm,
			untouch,
			history,
			signupButtonLabel,
			source,
			partner,
			parentSponsorId,
			match,
			location,
			email,
			helpContactUrl,
			intl,
			shop,
			brand,
			logout,
		} = this.props;

		const onToogleSignin =
			onToogleSigninForm && typeof onToogleSigninForm === "function"
				? onToogleSigninForm
				: () => {
						const parentPath = getParentPath(location.pathname);
						history.push({ pathname: `${parentPath}/login` });
				  };

		const enableFacebookLogin = brand !== BRANDS.AX;

		return (
			<Switch>
				<Route
					path={[
						`${match.url}/registration-succeeded`,
						`${match.url}/registration-confirmed`,
						`${match.url}/registration-not-confirmed`,
						`${match.url}/resend-confirmed`,
						`${match.url}/user-not-found`,
					]}
					render={() => (
						<RegisterMessage
							match={match}
							email={email}
							partner={partner}
							helpContactUrl={helpContactUrl}
							intl={intl}
							shop={shop}
						/>
					)}
				/>

				<Route
					render={() => (
						<Signup
							cnilDocumentName={cnilDocumentName}
							confidentialiteDocumentName={confidentialiteDocumentName}
							headline={headline}
							footer={footer}
							showBottomFacebook={enableFacebookLogin && showBottomFacebook}
							showTopFacebook={enableFacebookLogin && showTopFacebook}
							signupButtonLabel={signupButtonLabel}
							onSubmit={(data, dispatch) => {
								logout();

								return get(partner, "isDoubleOptin")
									? registerPromise({
											data,
											partner,
											match,
											history,
											dispatch,
											shop,
											onAuthSuccess,
									  })
									: signupPromise({
											history,
											data,
											dispatch,
											partner,
											source,
											parentSponsorId,
											onAuthSuccess,
									  });
							}}
							facebookOnSuccess={(success, dispatch) => {
								if (parentSponsorId && partner.enableSponsorship) {
									axios
										.post(
											env("SPONSORSHIP_API_URL") + "/sponsorship/subscribe",
											{
												sponsorId: parentSponsorId,
												sponsored: success.data.email,
											},
											{
												headers: {
													"Content-type": "application/json",
													Authorization: success.data.token,
												},
											}
										)
										.then(() => {
											sendTagOnSponsorshipSubscribeSuccess({
												sponsored: success.data.email,
												parentSponsorId: parentSponsorId,
											});
										})
										.catch(err => {
											sendTagOnSponsorshipSubscribeFailed({
												sponsored: success.data.email,
												status:
													err.response.data.status || err.response.status,
											});
										});
								}

								if (onAuthSuccess && typeof onAuthSuccess === "function") {
									onAuthSuccess(success, dispatch);
								} else {
									history.replace("/listing"); // TODO pour le cas /home/signup. A refactorer
								}
							}}
							onToogleSigninForm={onToogleSignin}
							untouch={untouch}
						/>
					)}
				/>
			</Switch>
		);
	}
}

SignupContainer.propTypes = {
	match: PropTypes.object,
	location: PropTypes.object,
	parentSponsorId: PropTypes.string,
	source: PropTypes.string,
	helpContactUrl: PropTypes.string,
	shop: PropTypes.string,
	email: PropTypes.string,
	partner: partnerShape,
	untouch: PropTypes.func,
	onAuthSuccess: PropTypes.func,
	logout: PropTypes.func,
	onToogleSigninForm: PropTypes.func,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	showTopFacebook: PropTypes.bool,
	showBottomFacebook: PropTypes.bool,
	confidentialiteDocumentName: PropTypes.string,
	cnilDocumentName: PropTypes.string,
	signupButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	history: PropTypes.shape({ push: PropTypes.func, replace: PropTypes.func }),
	intl: PropTypes.object,
	brand: brandPropTypes,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ untouch, logout }, dispatch);
};

const mapStateToProps = state => {
	const contextCallCenter = getContextCallCenter({})(state);
	return {
		confidentialiteDocumentName: state.documents.confidentialite,
		cnilDocumentName: state.documents.cnil,
		source: state.marketing.source,
		partner: state.partner,
		parentSponsorId: state.sponsorship.parentSponsorId,
		helpContactUrl: contextCallCenter.helpContactUrl,
		email: state.email,
		shop: state.shop,
		brand: state.brand.code,
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(injectIntl(SignupContainer))
);
