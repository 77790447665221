import { createSelector } from "reselect";

const getLandingPages = state => state.landingPagesAuth.landingPages;
const getActiveLandingPageCode = state => state.landingPagesAuth.activeLandingPageCode;

export const getCurrentLandingPage = createSelector(
	[getLandingPages, getActiveLandingPageCode],
	(landingPages = [], activeLandingPageCode) => {
		return landingPages.find(landingPage => landingPage.code === activeLandingPageCode);
	}
);
