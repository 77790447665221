import {
	CHECK_AVAILABILITIES_REQUEST,
	CLEAR_BOOKING,
	CLEAR_PROMOTION_VALUE,
	SEND_PROMOTION_CODE_SUCCESS,
} from "app/actionTypes";

const defaultState = {
	status: undefined,
	promotionValue: 0,
	promotionCode: undefined,
	message: "",
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case CHECK_AVAILABILITIES_REQUEST:
		case CLEAR_BOOKING: {
			return defaultState;
		}
		case SEND_PROMOTION_CODE_SUCCESS:
			return {
				...state,
				...{
					status: action.res.data.status,
					message: action.res.data.message,
					promotionValue: action.res.data.value,
					promotionCode:
						action.res.data.value > 0
							? JSON.parse(action.res.config.data).promoCode
							: undefined,
				},
			};
		case CLEAR_PROMOTION_VALUE:
			return defaultState;

		default:
			return state;
	}
};
