import { memo } from "react";
import "./HomeMarketingMobile.scss";
import PropTypes from "prop-types";

const HomeMarketingMobile = ({ image }) => {
	return (
		<div className="home-marketing-mobile">
			<img src={image} />
		</div>
	);
};

HomeMarketingMobile.propTypes = {
	image: PropTypes.string,
};

export default memo(HomeMarketingMobile);
