import { combineValidators, isRequired } from "revalidate";

export const fields = ["bookingReference", "email"];

export const validate = combineValidators({
	bookingReference: isRequired({
		message: { id: "error.booking.reference.required.error" },
	}),
	email: isRequired({
		message: { id: "error.booking.email.required.error" },
	}),
});
