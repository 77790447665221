import { memo } from "react";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import PropTypes from "prop-types";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
};

const HomeBackgroundMobile = ({ image, thumbnail }) => {
	return (
		<BackgroundProgressiveImg
			sizes={IMG_SIZES}
			src={image}
			thumbnail={thumbnail}
			backgroundPosition="right center"
		/>
	);
};

HomeBackgroundMobile.propTypes = {
	image: PropTypes.string,
	thumbnail: PropTypes.string,
};

export default memo(HomeBackgroundMobile);
