import { createSelector } from "reselect";
import get from "lodash/get";

const getMyBookings = state => state.myBookings;

export const getOpenCustomerRequestsCount = createSelector(
	[getMyBookings],
	(myBookings = {}) => {
		const futureBookings = myBookings.futureBookings || [];
		const pastBookings = myBookings.pastBookings || [];

		let total = 0;

		futureBookings.forEach(futureBooking => {
			const requestNotRead = get(futureBooking, "customerRequestSummary.requestNotRead", 0);
			total = total + requestNotRead;
		});

		pastBookings.forEach(pastBooking => {
			const requestNotRead = get(pastBooking, "customerRequestSummary.requestNotRead", 0);
			total = total + requestNotRead;
		});

		return total;
	}
);
