import { useContext } from "react";
import AnalyticsContext from "app/utils/analytics/AnalyticsContext";
import { mapPageStoreToTag, mapUserStoreToTag } from "app/utils/analytics-store-mapper";
import { useLocation } from "react-router-dom";
import { sendDataToAnalyticsEndPoint } from "app/utils/analytics";

const dataLayer = typeof window !== "undefined" ? window.dataLayer : [];

export const useAnalytics = () => {
	const { state } = useContext(AnalyticsContext);
	const { pathname, search } = useLocation();

	return {
		track: (eventName, payload = {}) => {
			if (dataLayer) {
				const tagData = {
					event: eventName,
					merchCode: state.merchandising?.current?.code,
					brand: state.brand.code,
					shop: state.shop,
					page: mapPageStoreToTag({ pathname, search, state }),
					partnerCode: state.partner?.code,
					hotjarSessionId: window._hjSettings?.hjid, // id de session de recording hotjar
					user: mapUserStoreToTag(state),
					...payload,
				};

				dataLayer.push(tagData);

				if (process.env.NODE_ENV === "production") {
					sendDataToAnalyticsEndPoint(tagData);
				}
			}
		},
	};
};
