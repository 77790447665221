import includes from "lodash/includes";
import compact from "lodash/compact";
import {
	BRANDS,
	OFFER_CONTRACT_TYPES,
	OFFER_TYPES,
	PASSENGER_TYPE,
	UTM_MEDIUM,
	UTM_SOURCE,
} from "app/constants";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { stringify } from "qs";

/**
 * Construit une quotationPayload à partir des query params.
 * L'object quotationPayload est le payload de la réquête de demande de quotation
 * @param query
 * @returns {*} object vide si au moins un champ requis est absent. la quotationPayload sinon
 */
export const extractQuotationPayloadFromQueryParams = (query, partnerCode, uuid, brand) => {
	const queryProperties = Object.keys(query);
	const requiredQuotationPayloadField = [
		"config",
		"offerType",
		"contractType",
		"departureDate",
		"duration",
	];

	if (query.contractType !== OFFER_CONTRACT_TYPES.RENTAL) {
		requiredQuotationPayloadField.push("adults");
	}

	let queryContainsQuotationPayload = true;

	requiredQuotationPayloadField.forEach(property => {
		queryContainsQuotationPayload =
			queryContainsQuotationPayload && includes(queryProperties, property);
	});

	let childrenBirthdates = [];
	if (Array.isArray(query.childrenBirthdates)) {
		childrenBirthdates = query.childrenBirthdates.map(birthdate => {
			// dans la quotation Url, le mois 1 est janvier
			const birthData = JSON.parse(birthdate);
			birthData.month =
				brand === BRANDS.VP || query.v2 ? birthData.month - 1 : birthData.month;
			return birthData;
		});
	} else if (typeof query.childrenBirthdates === "string") {
		// dans la quotation Url, le mois 1 est janvier
		const birthData = JSON.parse(query.childrenBirthdates);
		birthData.month = brand === BRANDS.VP || query.v2 ? birthData.month - 1 : birthData.month;
		childrenBirthdates = [].concat(birthData);
	}

	let infantsBirthdates = [];
	if (Array.isArray(query.infantsBirthdates)) {
		infantsBirthdates = query.infantsBirthdates.map(birthdate => {
			// dans la quotation Url, le mois 1 est janvier
			const birthData = JSON.parse(birthdate);
			birthData.month =
				brand === BRANDS.VP || query.v2 ? birthData.month - 1 : birthData.month;
			return birthData;
		});
	} else if (typeof query.infantsBirthdates === "string") {
		// dans la quotation Url, le mois 1 est janvier
		const birthData = JSON.parse(query.infantsBirthdates);
		birthData.month = brand === BRANDS.VP || query.v2 ? birthData.month - 1 : birthData.month;
		infantsBirthdates = [].concat(birthData);
	}

	let quotationPayload = {
		config: query.config,
		partnerCode,
		uuid,
		offerType: query.offerType,
		contractType: query.contractType,
		offerLabel: query.offerLabel,
		departureDate: Number(query.departureDate),
		duration: Number(query.duration),
	};

	if (query.price) {
		quotationPayload.price = Number(query.price);
	}

	if (query.endDate) {
		quotationPayload.endDate = Number(query.endDate);
	}

	if (query.offerType !== OFFER_TYPES.ACCOMODATION_ONLY) {
		quotationPayload.departureCityCode = query.departureCityCode;
		quotationPayload.departureCityLabel = query.departureCityLabel;
	}

	if (query.contractType !== OFFER_CONTRACT_TYPES.RENTAL) {
		quotationPayload.adults = Number(query.adults);
		quotationPayload.children = Number(query.children);
		quotationPayload.infants = Number(query.infants);
		quotationPayload.childrenBirthdates = childrenBirthdates;
		quotationPayload.infantsBirthdates = infantsBirthdates;
	} else {
		quotationPayload.accommodationLabel = query.accommodationLabel;
	}

	// ProductUrl est utilisé pour stocker le lien d'une fiche produit hébergé à l'extérieur
	if (query.productUrl) {
		quotationPayload.productUrl = query.productUrl;
	}

	// Pour vérifier que la quote correspond bien au produit
	if (query.productId) {
		quotationPayload.productId = query.productId;
	}

	return queryContainsQuotationPayload ? quotationPayload : {};
};

export const getSiteSection = (pathname = {}, shop) => {
	const SITE_SECTIONS = {
		signup: "homePage",
		login: "homePage",
		listing: "listingPage",
		merch: "merchandisingPage",
		magazine: "magazinePage",
		lpplage1: "plage1LandingPage", // la query plage1 est concatene au pathname
		lpplage2: "plage2LandingPage",
		lpcity: "cityLandingPage",
		lpevasion: "evasionLandingPage",
		lpluxe: "luxeLandingPage",
		quote: "quotationPage",
		payment: "paymentPage",
		confirmation: "confirmationPage",
		auth: "authPage",
		profile: "detailsPage",
		mybookings: "bookingPage",
		subscriptions: "subscriptionPage",
		cgv: "cgvPage",
		insurance: "insurancePage",
		"not-found": "notFoundPage",
		"technical-error": "technicalErrorPage",
		unsubscribe: "unsubscribePage",
		resetpassword: "resetpasswordPage",
		changepassword: "changepasswordPage",
		search: "searchPage",
		break: "breakPage",
		[shop]: "searchBookingPage", // page accueil du site institutionnelle sur l'url /fr-FR/
		recover: "recoverBookingData",
	};

	const pathParts = compact(pathname.split("/"));

	const revelantPathPart = pathParts[pathParts.length - 1]; // si revelantPathPart = undefined alors cela veut dire que l'on est sur /fr-FR/ (site institutionnel)

	if (!revelantPathPart) {
		return "homePage";
	}
	return SITE_SECTIONS[revelantPathPart] ? SITE_SECTIONS[revelantPathPart] : "productPage";
};

export const isAbsoluteUrl = url => {
	return url && url.indexOf("http") !== -1;
};

export const replaceShopInUrl = (url, oldShop, newShop) => {
	if (url) {
		return newShop ? url.replace(`/${oldShop}`, `/${newShop}`) : url;
	}
	return undefined;
};

export const extractPartnerDataFromQuery = (partnerDataNames = [], query = {}) => {
	const partnerData = {};

	partnerDataNames.forEach(partnerDataName => {
		const partnerDataFromQuery = query[partnerDataName];

		if (partnerDataFromQuery) {
			partnerData[partnerDataName] = partnerDataFromQuery;
		}
	});

	return partnerData;
};

export const extractPartnerTokensFromQuery = ({ partnerTokenNames = [], query = {} }) => {
	const partnerData = {};

	partnerTokenNames.forEach(partnerTokenName => {
		const partnerDataFromQuery = query[partnerTokenName];

		if (partnerDataFromQuery) {
			partnerData[partnerTokenName] = partnerDataFromQuery;
		}
	});

	return partnerData;
};

export const buildQuotationReloadUrl = (quoteUrl = "", state = {}, query = "") => {
	const queryObject = {
		uuid: state.auth.uuid,
		offerType: state.booking.offer.type,
		contractType: state.booking.offer.contractType,
		partnercode: state.partner.code,
		config: state.booking.config,
		productUri: state.ficheProduit.uri,
		productId: state.ficheProduit.id,
		departureDate: state.booking.departureDate,
		duration: state.booking.duration.value,
		v2: "true", // temporaire : A virer le 28 juin. Utiliser pour differencier les quotationURl qui necessite de modifier month
	};

	if (state.booking.offer.type === OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION) {
		queryObject.departureCityLabel = state.booking.departureCity.label;
		queryObject.departureCityCode = state.booking.departureCity.code;
	} else if (state.booking.offer.type === OFFER_TYPES.ACCOMODATION_ONLY) {
		queryObject.endDate = state.booking.endDate;
	}

	if (state.booking.offer.contractType !== OFFER_CONTRACT_TYPES.RENTAL) {
		queryObject.adults = state.booking.adults;
		queryObject.children = state.booking.children;
		queryObject.infants = state.booking.infants;
		queryObject.offerLabel = state.booking.offer.label;

		if (state.ficheProduit.isChildAgeRequired) {
			const passengers = cloneDeep(state.booking.passengers);
			queryObject.childrenBirthdates = passengers
				.filter(passenger => passenger.type === PASSENGER_TYPE.CHILD)
				.map(passenger => {
					// dans la quotation Url, le mois 1 est janvier
					passenger.birthdate.month = passenger.birthdate.month + 1;
					return JSON.stringify(passenger.birthdate);
				});
			queryObject.infantsBirthdates = passengers
				.filter(passenger => passenger.type === PASSENGER_TYPE.INFANT)
				.map(passenger => {
					// dans la quotation Url, le mois 1 est janvier
					passenger.birthdate.month = passenger.birthdate.month + 1;
					return JSON.stringify(passenger.birthdate);
				});
		}
	} else {
		queryObject.accommodationLabel =
			state.booking.rentalAccommodation && state.booking.rentalAccommodation.accommodation;
	}

	// On rajoute les query permettant de construire le tokenFromPartner
	if (get(state, "brand.partnerTokensNames")) {
		state.brand.partnerTokensNames.forEach(partnerTokenName => {
			queryObject[partnerTokenName] = query[partnerTokenName];
		});
	}

	return `${quoteUrl}?${stringify(queryObject)}`;
};

export const isProductPage = pathname =>
	pathname === "/:productUri" || pathname === "/product/id/:productId";

/**
 * Verify if we come from remarketing email redirectind the user to an already made quotation
 */
export const checkIfComingFromRemarketingEmail = (utmSource, utmMedium) => {
	return utmSource === UTM_SOURCE.REMARKETING && utmMedium === UTM_MEDIUM.EMAIL_REMARKETING;
};

export const buildPartnerTokenFromQuery = (query = {}) => {
	let token = "";
	const values = Object.values(query);

	values.forEach(value => {
		if (value) {
			token += value;
		}
	});

	return token;
};

export const extractProductUriFromEndpoint = ({ url = "" }) => {
	const redirectUriParts = url.split(`/`);
	const productUriWithExtension = redirectUriParts[redirectUriParts.length - 1];
	return productUriWithExtension && productUriWithExtension.replace(".json", "");
};
