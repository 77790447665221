import find from "lodash/find";

/**
 * Check is there is at least one field touched
 * @param fields
 * @returns {object|undefined}
 */
export function anyFieldTouched(fields) {
	const touchedField = find(fields, field => {
		return field.touched;
	});

	return touchedField ? true : false;
}
