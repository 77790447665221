import {
	addMinutes,
	differenceInDays,
	differenceInHours,
	differenceInYears,
	eachMonthOfInterval,
	isAfter,
} from "date-fns";
import every from "lodash/every";
import intersection from "lodash/intersection";
import values from "lodash/values";
import isUndefined from "lodash/isUndefined";
import deburr from "lodash/deburr";
import parser from "ua-parser-js";

import {
	HTTP_STATUS_CODES,
	LIB_PHONE_NUMBER_TYPES,
	PRODUCTS_FILTERS_KEY,
	TRANSPORTATION_TYPES,
} from "app/constants";
import { PRODUCT_EXPIRATION_DAY_LIMIT } from "src/@brand/appConstants";

import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import flattenDeep from "lodash/flattenDeep";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";

import set from "lodash/set";
import { parsePhoneNumber } from "react-phone-number-input/max";

export const isServerSide = typeof window === "undefined";

export const getGoogleMapsStaticUrlForCoords = ({ latitude, longitude, options = {} }) => {
	const { googleAPIKey } = options;

	if (!googleAPIKey) {
		return "";
	}

	const HEIGHT = Math.floor(options.height) || 500;
	const WIDTH = Math.floor(options.width) || 500;
	const zoom = options.zoom || 12;
	const base = "https://maps.googleapis.com/maps/api/staticmap";
	return `${base}${encodeURIComponent(
		`?center=${latitude},${longitude}&size=${WIDTH}x${HEIGHT}&key=${googleAPIKey}&maptype=roadmap&zoom=${zoom}&markers=size:mid|${latitude},${longitude}`
	)}`;
};

export const containsTrainTransportationTypes = (transportationTypes = []) => {
	return transportationTypes.includes(TRANSPORTATION_TYPES.TRAIN);
};

export const isDeepUndefined = object => {
	if (isUndefined(object)) {
		return true;
	}
	return every(object, property => {
		let empty;

		if (typeof property === "object") {
			empty = isDeepUndefined(property);
		} else {
			empty = isUndefined(property);
		}

		return empty;
	});
};

/**
 * Converti par exemple "Les ventes flash" par "les-ventes-flash".
 * Supprime également les accents
 * @param url
 * @returns {string}
 */
export const normalizeFilterPath = url => deburr(url.replace(/ /g, "-").toLowerCase());

/**
 * retire tous les query qui ne sont pas utilisés pour les filtres listing et fusionne
 * les query des filtres issus de la query filterBy
 */
export const normalizeQueryFilters = query => {
	const filterKeys = intersection(values(PRODUCTS_FILTERS_KEY), Object.keys(query));

	const formattedFilters = `{${filterKeys.reduce(
		(stringAcc, filterKey, index) =>
			index < filterKeys.length - 1
				? stringAcc + `"${filterKey}":"${query[filterKey]}",`
				: stringAcc + `"${filterKey}":"${query[filterKey]}"`,
		""
	)}}`;

	const parsedFormattedFilters = JSON.parse(formattedFilters);

	return {
		...parsedFormattedFilters,
		...JSON.parse(query.filterBy || "{}"),
	};
};

/**
 * Calculate duration between 2 dates and return the day and hour part of the duration
 * @param {number} begin - begin date in ms
 * @param {number} end - begin date in ms
 * @returns {object} {day: 1, hour: 2}
 */
export function getDuration(begin, end) {
	if (end < begin) {
		return {
			day: 0,
			hour: 0,
		};
	}

	var ms = end - begin;
	var seconds = Math.floor(ms / 1000);
	var minutes = Math.floor(seconds / 60);
	var hours = Math.floor(minutes / 60);
	var days = Math.floor(hours / 24);
	var remainingHours = hours % 24;
	return {
		day: days,
		hour: remainingHours,
	};
}

/**
 * Calculate the total of durations
 * @param durations array of duration string ["hh:mm"]
 * @returns {*} total duration in "hh:mm"
 */
export const calculateStopDuration = (durations = []) => {
	if (durations.length === 0) {
		return undefined;
	}

	return durations.reduce((prevDuration, currentDuration) => {
		const prevDurationPart = prevDuration.split(":");
		const prevHour = prevDurationPart[0];
		const prevMinutes = prevDurationPart[1];

		const currentDurationPart = currentDuration.split(":");
		const currentHour = currentDurationPart[0];
		const currentMinutes = currentDurationPart[1];

		let minuteDuration = Number(prevMinutes) + Number(currentMinutes);
		const hourFromMinutes = Math.floor(minuteDuration / 60);
		minuteDuration = minuteDuration - hourFromMinutes * 60;

		const hourDuration = Number(prevHour) + Number(currentHour) + hourFromMinutes;

		minuteDuration = minuteDuration > 9 ? minuteDuration : "0" + minuteDuration;

		return `${hourDuration}:${minuteDuration}`;
	});
};

/**
 * Get number of milliseconds of duration
 * @param duration duration string ["hh:mm"]
 */
export const getDurationAsMilliseconds = duration => {
	if (!duration) {
		return undefined;
	}
	const durationPart = duration.split(":");
	const hours = durationPart[0];
	const minutes = durationPart[1];

	return Number(hours) * 60 * 60 * 1000 + Number(minutes) * 60 * 1000;
};

/**
 * retourne le nombre de jours dans un mois
 * @param month 1 pour janvier
 */
export const getDaysInMonth = month => {
	// 0 est le dernier jour du mois précédent
	// on choisit 1972 pour etre sur de retourner 29 pour février
	return new Date(1972, month, 0).getDate();
};

const daysInMonth = (m, y) => {
	switch (m) {
		case 1:
			return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
		case 8:
		case 3:
		case 5:
		case 10:
			return 30;
		default:
			return 31;
	}
};

/**
 * @see https://gomakethings.com/how-to-check-if-a-date-is-valid-with-vanilla-javascript/
 * @param year
 * @param month 1 pour janvier
 * @param day
 * @returns {boolean}
 */
export const checkDateValidity = (year, month, day) => {
	const monthJavascript = parseInt(month, 10) - 1;
	return (
		monthJavascript >= 0 &&
		monthJavascript < 12 &&
		day > 0 &&
		day <= daysInMonth(monthJavascript, year)
	);
};

/**
 * Return days count between 2 dates
 * @param start instance of Date or "DD-MM-YYYY"
 * @param end instance of Date or "DD-MM-YYYY"
 * @returns {number|*}
 */
export const getDayCount = (start, end) => {
	return differenceInDays(new Date(end), new Date(start));
};

/**
 * Return hours count between 2 dates
 * @param start instance of Date or "DD-MM-YYYY"
 * @param end instance of Date or "DD-MM-YYYY"
 * @returns {number|*}
 */
export const getHoursCount = (start, end) => {
	return differenceInHours(new Date(end), new Date(start));
};

/**
 * Get duration ["hh:mm"] from milliseconds
 * @param duration duration string ["hh:mm"]
 */
export const getMillisecondsAsDuration = milliseconds => {
	if (!milliseconds || milliseconds === 0) {
		return "00:00";
	}

	let minutes = milliseconds / 60 / 1000;

	let hourDuration = Math.floor(minutes / 60);

	let minuteDuration = minutes - hourDuration * 60;

	minuteDuration = minuteDuration > 9 ? minuteDuration : "0" + minuteDuration;
	hourDuration = hourDuration > 9 ? hourDuration : "0" + hourDuration;
	return `${hourDuration}:${minuteDuration}`;
};

/**
 * Check if a timestamp + minutes < now
 * @param timestamp
 * @param minutes number of minutes
 * @returns {boolean} true is timestamp + minutes < now , false otherwise
 */
export const isExpiredAfter = (timestamp, minutes) => {
	const today = new Date();
	const date = new Date(timestamp);
	const limiteDate = addMinutes(date, minutes);
	return isAfter(today, limiteDate);
};

export const calculateAgeFromBirthday = birthDate => {
	return differenceInYears(new Date(), new Date(birthDate));
};

export const getMonthsYearsInDateInterval = (minDate, maxDate) => {
	const result = eachMonthOfInterval({
		start: new Date(minDate),
		end: new Date(maxDate),
	});

	return result.map(date => {
		return date.getMonth() + "/" + date.getFullYear();
	});
};

export const hexToRgbA = (hex, opacity) => {
	let c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split("");
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = "0x" + c.join("");
		return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + "," + opacity + ")";
	}
	return "rgba(0,0,0,1)";
};

export const getTwoDigitsDate = date => (date < 10 ? "0" : "") + date;

/**
 * Retourne une Date UTC pour analytics au format YYYY-MM-DD
 * @param timestamp
 * @returns {string}
 */
export const getUTCDateForAnalytics = timestamp => {
	const utcDate = new Date(timestamp);
	const utcMonth =
		utcDate.getUTCMonth() + 1 >= 10
			? utcDate.getUTCMonth() + 1
			: "0" + (utcDate.getUTCMonth() + 1);
	const utcDay = utcDate.getUTCDate() >= 10 ? utcDate.getUTCDate() : "0" + utcDate.getUTCDate();
	return `${utcDate.getUTCFullYear()}-${utcMonth}-${utcDay}`;
};

/**
 * retourne le userAgent et le type de device
 * @returns {*}
 */
export const getDeviceInfo = () => {
	const userAgent = navigator.userAgent;

	if (userAgent) {
		const uaParser = parser(userAgent);
		const deviceType = uaParser.device.type || "desktop";

		return {
			userAgent,
			deviceType,
		};
	}

	return {};
};

export const deleteUniquePassengersNameErrorsForm = state => {
	if (state._active) {
		const [passenger, indexPassenger, fieldPassenger] =
			state._active.match(/.*passengers\[(.*)\]\.name\.(.*Name)/) || [];
		let passengerNameState;

		if (passenger && indexPassenger && fieldPassenger) {
			const fieldToChange =
				fieldPassenger === "lastName"
					? {
							firstName:
								get(
									state,
									`${state._active.match(/(.*name)/)[0]}.firstName.submitError.id`
								) === "error.passengers.unique.invalid"
									? {
											...omit(
												get(state, state._active.match(/(.*name)/)[0])
													.firstName,
												"submitError"
											),
									  }
									: get(state, state._active.match(/(.*name)/)[0]).firstName,
					  }
					: {
							lastName:
								get(
									state,
									`${state._active.match(/(.*name)/)[0]}.lastName.submitError.id`
								) === "error.passengers.unique.invalid"
									? {
											...omit(
												get(state, state._active.match(/(.*name)/)[0])
													.lastName,
												"submitError"
											),
									  }
									: get(state, state._active.match(/(.*name)/)[0]).lastName,
					  };
			passengerNameState = {
				...get(state, state._active.match(/(.*name)/)[0]),
				...fieldToChange,
			};
			set(state, state._active.match(/(.*name)/)[0], passengerNameState);
		}
	}
	return state;
};
export const getRouteComponentParts = route => {
	const components = route.getComponent();
	const Header = components.header;
	const Main = components.main;
	const Footer = components.footer;
	const Aside = components.aside;
	let childRoutes = [];
	if (typeof route.getChildRoutes === "function") {
		childRoutes = route.getChildRoutes();
	}

	return {
		Aside,
		Header,
		Main,
		Footer,
		childRoutes,
		onEnter: route.onEnter,
	};
};

/**
 * convertit la valeur en un montant valide
 * 32 => 32
 * 32.4 => 32.4
 * "32" => 32
 * "32.3" => 32.3
 * "32,3" => 32.3
 * @param amount
 * @returns {number|undefined}
 */
export const parseAmount = amount => {
	let amountToParse = amount;

	if (amount && typeof amount === "string") {
		amountToParse = amount.replace(",", ".");
	}

	if ((amountToParse !== 0 && !amountToParse) || (amountToParse && isNaN(amountToParse))) {
		return undefined;
	}

	return parseFloat(amountToParse);
};

export const getShopData = (shops = [], shop) => {
	const shopData = shops.find(currentShop => {
		const locales = currentShop.locales;
		return locales.find(locale => `${locale}-${currentShop.sellingCountry}` === shop);
	});
	return shopData;
};

export const getDomainWithShop = (domainsByShop = [], shop, isLocalHost) => {
	if (isLocalHost) {
		return `http://localhost:8080/${shop}`;
	}

	const domain = domainsByShop.find(domainByShop => domainByShop.shop === shop);
	return domain && domain.domain && `${domain.domain}/${domain.shop}`;
};

/**
 * get all domains of brand for each shop.
 * if envVars.BASE_URL is set, it will be used to overwrite the domain.
 * envVars.BASE_URL is set on all env except live
 * @param shops
 * @param envVars
 * @returns {{shop: string, domain: string|*}[]}
 */
export const buildDomainsByShop = (shops = [], envVars = {}) => {
	let domainsByShop = shops.map((shop = {}) => {
		const marketingCodes = shop.marketingCodes || [];
		const domainsByMarketingCodes = marketingCodes.map(marketingCode => {
			return {
				domain: marketingCode?.technicalVars?.BASE_URL,
				shop: `${marketingCode.locale}-${shop.sellingCountry}`,
			};
		});

		return domainsByMarketingCodes;
	});

	domainsByShop = flattenDeep(domainsByShop).map(data => {
		return {
			shop: data.shop,
			domain: (envVars.BASE_URL && envVars.BASE_URL[data.shop]) || data.domain,
		};
	});

	return uniqBy(domainsByShop, domain => domain.shop + domain.domain);
};

export const injectScript = htmlPayload => {
	const s = document.createElement("script");
	s.type = "text/javascript";
	document.getElementsByTagName("head")[0].appendChild(s);
	s.innerText = htmlPayload;
};

export const computeActivityTotal = (guestsOfDate = [], activityCode, fields = {}) => {
	let total = 0;
	if (guestsOfDate) {
		guestsOfDate.forEach(guest => {
			const guestCountFieldIndex = `activity-${guest.code}-${activityCode}`;
			const guestCount = fields[guestCountFieldIndex].value;
			const guestPrices = guest.pricePerQuantity[guestCount];
			if (guestPrices) {
				const price = guestPrices.price || 0;
				const discount = guestPrices.discount || 0;
				total += price + discount;
			}
		});
	}

	return total;
};

export const computeActivityExtraAmount = (guestsOfDate = [], activityCode, fields = {}) => {
	let total = 0;
	if (guestsOfDate) {
		guestsOfDate.forEach(guest => {
			const guestCountFieldIndex = `activity-${guest.code}-${activityCode}`;
			const guestCount = fields[guestCountFieldIndex].value;
			const guestPrices = guest.pricePerQuantity[guestCount];
			if (guestPrices) {
				const price = guestPrices.price || 0;
				total += price;
			}
		});
	}

	return total;
};

export const computeGuestDiscount = (guestsOfDate = [], activityCode, fields = {}) => {
	let total = 0;
	if (guestsOfDate) {
		guestsOfDate.forEach(guest => {
			const guestCountFieldIndex = `activity-${guest.code}-${activityCode}`;
			const guestCount = fields[guestCountFieldIndex].value;
			const guestPrices = guest.pricePerQuantity[guestCount];
			if (guestPrices) {
				const discount = guestPrices.discount || 0;
				total += discount;
			}
		});
	}

	return total;
};

export const checkIfActivityIncluded = (guests = []) => {
	const guest = guests.find(guest => {
		return guest.includedGuest > 0;
	});

	return Boolean(guest);
};

export const removeTrailingZeroOfInteger = (number, decimalCount = 2) => {
	const realNumber = Number(number);

	if (Number.isInteger(realNumber)) {
		return realNumber.toFixed(0);
	}
	return realNumber.toFixed(decimalCount);
};

export const validatePhoneIntl = phoneNumber => {
	const possibleTypes = [
		LIB_PHONE_NUMBER_TYPES.FIXED_LINE,
		LIB_PHONE_NUMBER_TYPES.FIXED_LINE_OR_MOBILE,
		LIB_PHONE_NUMBER_TYPES.UNDEFINED,
		LIB_PHONE_NUMBER_TYPES.PERSONAL_NUMBER,
		LIB_PHONE_NUMBER_TYPES.MOBILE,
		LIB_PHONE_NUMBER_TYPES.VOIP,
		LIB_PHONE_NUMBER_TYPES.PAGER,
		LIB_PHONE_NUMBER_TYPES.TOLL_FREE,
	];

	const phoneAnalysis = parsePhoneNumber(phoneNumber);

	if (phoneAnalysis) {
		return possibleTypes.includes(phoneAnalysis.getType());
	}
	return false; // invalid number
};

export const sortProducts = ({ products }) => {
	const isFlashSale = product =>
		get(product, ["expiration", "day"]) <= PRODUCT_EXPIRATION_DAY_LIMIT;
	return orderBy(products, [isFlashSale, "rank"], ["desc", "asc"]) || [];
};

export const mapContactFormToProfilePayload = values => {
	const payload = Object.assign({}, values.contact);
	// on met a jour la numero de telephone dans l'adresse car c'est celle-ci qui est attendu par le service /saveProfile
	payload.address.phone = values.contact.phone;
	// on supprime les propriétés inutiles sinon erreur 400 du service /saveProfile
	delete payload.phone;
	delete payload.email;
	return payload;
};

export const mapErrorHttpCodeToMessage = status => {
	switch (status) {
		case HTTP_STATUS_CODES.UNAUTHORIZED:
			return { id: "error.must.be.authenticated" };
		case HTTP_STATUS_CODES.FORBIDDEN:
		case HTTP_STATUS_CODES.NOT_FOUND:
			return { id: "error.user.not.identified" };
		default:
			return { id: "error.generic" };
	}
};

export const isObject = value => {
	return Boolean(value) && value.constructor === Object;
};

export const slugtifyProductRating = productRating =>
	productRating && productRating.length > 0
		? productRating
				.toString()
				.replace("_", "")
				.toLowerCase()
		: "";
