import { memo } from "react";
import SmartDPHeader from "app/pages/SmartDP/SmartDPHeader/SmartDPHeader";
import "./HomeHeader.scss";

const HomeHeader = () => {
	return (
		<header className="home-header">
			<SmartDPHeader displayBackButton={false} />
		</header>
	);
};

export default memo(HomeHeader);
