import DOMPurify from "dompurify";
import { isServerSide } from "app/utils/utils";

export const htmlSanitize = html => {
	// run only on front because I got a TypeError: ne.sanitize is not a function otherwise
	return !isServerSide ? DOMPurify.sanitize(html) : html;
};

const INPUT_CONFIG = {
	FORBID_TAGS: ["script", "a"],
	KEEP_CONTENT: true,
};

export const inputSanitize = value => {
	return DOMPurify.sanitize(value, INPUT_CONFIG).replaceAll("&lt;", "");
};
