import HomeHeader from "@brand/Home/HomeHeader";
import { connect } from "react-redux";
import { getFallbackRedirectUrl } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	return {
		brand: state.brand.code,
		fallbackRedirectUrl: getFallbackRedirectUrl(state),
	};
};

export default connect(mapStateToProps)(HomeHeader);
