import axios from "axios";
import { sendTagOnError } from "app/utils/analytics";
import {
	FETCH_PROFILE_FAILURE,
	FETCH_PROFILE_REQUEST,
	FETCH_PROFILE_SUCCESS,
	SAVE_PROFILE,
} from "app/actionTypes";
import getHistory from "app/configureHistory";
import { logout } from "app/pages/Auth/AuthActions";
import { getCredentials } from "app/utils/auth";
import pruneEmpty from "app/utils/pruneEmpty";
import env from "app/utils/env";
import { SET_NEWSLETTER_FREQUENCY_MODAL_VIEWED } from "app/pages/Account/Subscriptions/subscriptionsActionTypes";

export const postSubscriptions = data => {
	const { token } = getCredentials();
	const _data = pruneEmpty(data);

	const params = {};
	if (_data.silent) {
		// permet de ne pas envoyer d'email de modification de compte et d'harceler le client
		params.silent = true;
		delete _data.silent;
	}
	return {
		type: SAVE_PROFILE,
		promise: axios.post(env("USER_AUTH_API_URL") + "/me", _data, {
			headers: {
				Authorization: token,
				"Content-type": "application/json",
			},
			params,
		}),
	};
};

export const setNewsletterFrequencyModalViewed = () => {
	return {
		type: SET_NEWSLETTER_FREQUENCY_MODAL_VIEWED,
	};
};

export const getSubscriptions = () => {
	return dispatch => {
		const { token } = getCredentials();
		const url = env("USER_AUTH_API_URL") + "/me";

		dispatch({
			type: FETCH_PROFILE_REQUEST,
		});

		axios
			.get(url, {
				headers: { Authorization: token },
			})
			.then(res => {
				dispatch({
					type: FETCH_PROFILE_SUCCESS,
					profile: res.data,
				});
			})
			.catch(err => {
				if (
					err &&
					err.response &&
					err.response.status &&
					(err.response.status === 401 || err.response.status === 403)
				) {
					dispatch(logout());
					getHistory().replace("/home/login");

					sendTagOnError(`Get ${url} : ${err.message} - ${err.response.data.message}`);
				} else {
					dispatch({
						type: FETCH_PROFILE_FAILURE,
					});

					sendTagOnError(`${url} : ${err.message}`);
				}
			});
	};
};
