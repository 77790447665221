import { Fragment, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { forceCheck } from "react-lazyload";
import { authSuccess } from "app/pages/Auth/AuthActions";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { acceptCookyPolicy } from "app/pages/.shared/CookiePolicyFooter/cookiePolicyActionCreators";
import { AUTH_LAYER_SHOW_DELAY, BRANDS, STRATEGY } from "app/constants";
import AuthLayerMobile from "app/pages/Auth/AuthLayerMobile/AuthLayerMobile";
import { getUUID, isAuthenticated } from "app/pages/Auth/authSelectors";
import FrequencyModal from "app/pages/.shared/NewsletterFrequency/FrequencyModal/FrequencyModal";
import { shouldShowFrequencyModal } from "app/pages/Account/Profile/profileSelector";
import {
	postSubscriptions,
	setNewsletterFrequencyModalViewed,
} from "app/pages/Account/Subscriptions/subscriptionsActionCreators";
import { sendTagOnSubscriptionUpdate } from "app/utils/analytics";
import AuthModal from "app/pages/Auth/AuthModal/AuthModal";
import { brandPropTypes } from "app/utils/propTypes";
import { Redirect } from "react-router-dom";
import useBlockViewportScroll from "app/pages/.shared/useBlockViewportScroll";
import { getHomeImages } from "app/reducers/partnerSelector";

const RequireSignupFirst = props => {
	const {
		strategy,
		uuid,
		resolution,
		acceptCookyPolicy,
		showFrequencyModal,
		renderComponent,
		postSubscriptions,
		setNewsletterFrequencyModalViewed,
		shop,
		brand,
		isAuthenticated,
		home = {},
	} = props;
	const [isAuthLayerVisible, setAuthLayerVisible] = useState(false);

	useEffect(() => {
		// La verification du uuid doit etre a l'interieur du setTimeout pour laisser le temps à la rehydratation
		// de valoriser l'uuid. De meme ne pas mettre AUTH_LAYER_SHOW_DELAY = 0s
		setTimeout(() => {
			if (
				!uuid &&
				strategy !== STRATEGY.OAUTH &&
				strategy !== STRATEGY.NAVIGATION_FIRST &&
				strategy !== STRATEGY.TRANSACTION_FIRST &&
				strategy !== STRATEGY.PRODUCT_FIRST &&
				brand !== BRANDS.XX
			) {
				setAuthLayerVisible(true);
				if (resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) {
					acceptCookyPolicy();
				}
				forceCheck(); // forcer l'apparition du footer qui est lazyloadé
			}
		}, AUTH_LAYER_SHOW_DELAY);
	}, [uuid, strategy, brand]);

	useBlockViewportScroll(isAuthLayerVisible || showFrequencyModal);

	const onAuthSuccess = useCallback(() => {
		setAuthLayerVisible(false);
	}, []);

	const saveFrequency = useCallback(data => {
		data.silent = true; // permet de ne pas envoyer d'email de modification de compte et d'harceler le client
		postSubscriptions(data).then(() => {
			sendTagOnSubscriptionUpdate(data.newsletterPreference);
		});
	}, []);

	const onFrequencyModalClose = useCallback(() => {
		setNewsletterFrequencyModalViewed();
	}, []);

	if (brand === BRANDS.XX) {
		if (strategy === STRATEGY.SIGNUP_FIRST && !isAuthenticated) {
			return (
				<Redirect
					to={{
						pathname: `/`,
					}}
				/>
			);
		}
		return renderComponent(props);
	} else if (resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM) {
		return isAuthLayerVisible ? (
			<AuthLayerMobile
				onAuthSuccess={onAuthSuccess}
				resolution={resolution}
				showAtolAbta={shop === "en-GB"}
				home={home}
			/>
		) : (
			<Fragment>
				{renderComponent(props)}
				<FrequencyModal
					isOpen={
						showFrequencyModal &&
						!isAuthLayerVisible &&
						strategy !== STRATEGY.TRANSACTION_FIRST &&
						strategy !== STRATEGY.NAVIGATION_FIRST
					}
					handleSubmit={saveFrequency}
					onModalClose={onFrequencyModalClose}
				/>
			</Fragment>
		);
	}

	return (
		<Fragment>
			{renderComponent(props)}
			{isAuthLayerVisible && (
				<AuthModal
					isOpen={isAuthLayerVisible}
					onAuthSuccess={onAuthSuccess}
					showAtolAbta={shop === "en-GB"}
					home={home}
				/>
			)}
			<FrequencyModal
				isOpen={
					showFrequencyModal &&
					!isAuthLayerVisible &&
					strategy !== STRATEGY.TRANSACTION_FIRST &&
					strategy !== STRATEGY.NAVIGATION_FIRST
				}
				handleSubmit={saveFrequency}
				onModalClose={onFrequencyModalClose}
			/>
		</Fragment>
	);
};

RequireSignupFirst.propTypes = {
	resolution: PropTypes.string,
	brand: brandPropTypes,
	uuid: PropTypes.string,
	shop: PropTypes.string,
	renderComponent: PropTypes.func,
	strategy: PropTypes.string,
	acceptCookyPolicy: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	showFrequencyModal: PropTypes.bool,
	postSubscriptions: PropTypes.func,
	setNewsletterFrequencyModalViewed: PropTypes.func,
	home: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		uuid: getUUID(state),
		shop: state.shop,
		brand: state.brand.code,
		strategy: state.partner.strategy,
		resolution: state.resolution,
		isAuthenticated: isAuthenticated(state),
		showFrequencyModal: shouldShowFrequencyModal(state),
		home: getHomeImages(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			authSuccess,
			acceptCookyPolicy,
			postSubscriptions,
			setNewsletterFrequencyModalViewed,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RequireSignupFirst);
