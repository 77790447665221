/* eslint-disable max-nested-callbacks,no-console */

export const fetchMessages = (apiURL, brand, shop) => {
	const lang = shop.slice(0, 2);

	const getMainMessages = import(/* webpackChunkName: "messages-main-i18n" */ `./messages/${lang}.json`);
	const getBrandMessages = import(/* webpackChunkName: "messages-brand-i18n" */ `./messages/brands/${brand}/${lang}.json`);
	const getMainDevMessages = import(/* webpackChunkName: "messages-main-dev-i18n" */ `./messages/${lang}.dev.json`).catch(
		error => {
			// TODO le catch ici permet de ne pas faire échouer le Promise.all
			console.log("no getMainDevMessages", error);
		}
	);
	const getBrandDevMessages = import(/* webpackChunkName: "messages-brand-dev-i18n" */ `./messages/brands/${brand}/${lang}.dev.json`).catch(
		error => {
			// TODO le catch ici permet de ne pas faire échouer le Promise.all
			console.log("no getMainDevMessages", error);
		}
	);

	return Promise.all([getMainMessages, getBrandMessages, getMainDevMessages, getBrandDevMessages])
		.then(([messages, brandsMessages, devMessages, brandDevMessages]) => {
			return {
				...messages,
				...devMessages,
				...brandsMessages,
				...brandDevMessages,
			};
		})
		.catch(error => {
			// TODO connecter Sentry
			console.error("fetchMessages error", error);
		});
};
