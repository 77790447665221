export default {
	home: {
		id: "page.description.home",
	},
	listing: {
		id: "page.description.listing",
	},
	magazine: {
		id: "page.description.magazine",
	},
	landing: {
		id: "page.description.landing",
	},
	merch: {
		id: "page.description.merch",
		description: "Merchandising page description",
		defaultMessage:
			"Découvrez cette semaine notre nouvelle sélection de Ventes Exclusives de Voyages négociées jusqu'à -70% - {siteTitle}",
	},
	profile: {
		id: "page.description.profile",
	},
	experts: {
		id: "page.description.experts",
	},
	help: {
		id: "page.description.help",
	},
	productSaveUpTo: {
		id: "page.description.product.saveupto",
	},
	productFrom: {
		id: "page.description.product.from",
	},
	productExclusive: {
		id: "page.description.product.exclusive",
	},
	subscription: {
		id: "page.description.subscription",
	},
	wishlist: {
		id: "page.description.wishlist",
	},
	mybookings: {
		id: "page.description.mybooking",
	},
	"404": {
		id: "page.description.404",
	},
};
