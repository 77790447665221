// import React from "react";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import promiseMiddleware from "./utils/promiseMiddleware";
// import performanceReduxMiddleware from "./utils/performanceReduxMiddleware";
import reducers from "./reducers";
import { autoRehydrate } from "redux-persist";

import { REHYDRATE } from "redux-persist/constants";
import createActionBuffer from "redux-action-buffer";

import { sentryMiddleware } from "app/utils/sentry";

const middlewares = [thunk, promiseMiddleware, sentryMiddleware];

let store;

export function configureStore(initialState) {
	if (typeof window !== "undefined") {
		middlewares.push(createActionBuffer(REHYDRATE));
		if (process.env.NODE_ENV !== "production") {
			// 	middlewares.push(performanceReduxMiddleware);
		}
	}

	let functionToCompose = [applyMiddleware(...middlewares)];

	if (typeof window !== "undefined") {
		functionToCompose.push(autoRehydrate());
	}

	if (process.env.NODE_ENV !== "production") {
		// @see https://github.com/garbles/why-did-you-update/issues/45
		// let createClass = React.createClass; // eslint-disable-line no-unused-vars
		// Object.defineProperty(React, 'createClass', {
		// 	set: (nextCreateClass) => {
		// 		createClass = nextCreateClass;
		// 	}
		// });
		// const {whyDidYouUpdate} = require('why-did-you-update');
		// whyDidYouUpdate(React, { include: /./ });
	}

	store = composeWithDevTools(...functionToCompose)(createStore)(reducers, initialState);

	if (typeof window !== "undefined" && window.Cypress) {
		window.store = store;
	}

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./reducers", () => {
			const nextRootReducer = require("./reducers");
			store.replaceReducer(nextRootReducer.default);
		});
	}

	return store;
}

export function getStore() {
	return store;
}
