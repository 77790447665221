import { FETCH_NAVLOGS_SUCCESS } from "app/pages/Listing/NavLogs/navLogActionTypes";
import { getDuration } from "app/utils/utils";
import { LOGOUT } from "app/actionTypes";

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_NAVLOGS_SUCCESS: {
			const navLog = action.res.data;
			let newNavLogs = [];
			const now = new Date();

			if (navLog && navLog.length > 0) {
				newNavLogs = navLog.map(product => {
					if (product.endAt) {
						product.expiration = getDuration(now.getTime(), product.endAt);
					}

					if (product.startAt) {
						product.availabilityDate = getDuration(now.getTime(), product.startAt);
					}

					return product;
				});
			}
			return newNavLogs;
		}
		case LOGOUT:
		default: {
			return state;
		}
	}
};
