import { memo } from "react";
import PropTypes from "prop-types";
import HomeHeaderContainer from "app/pages/Home/HomeHeaderContainer";
import HomeBackgroundMobile from "@brand/Home/mobile/HomeBackgroundMobile.jsx";
import "./AuthLayerMobile.scss";
import IconAtolProtected from "app/pages/.shared/static/icons/IconAtolProtected";
import IconABTACertification from "app/pages/.shared/BookWithConfidence/IconABTACertification";
import FooterContainer from "app/pages/Footer/FooterContainer";
import HomeMarketingMobile from "@brand/Home/mobile/HomeMarketingMobile";
import AuthLayerContainer from "app/pages/Auth/AuthLayer/AuthLayerContainer";

const IS_DEFAULT_SIGN_IN = false;

const AuthLayerMobile = ({ onAuthSuccess, showAtolAbta, home = {} }) => {
	return (
		<div className="auth-layer-mobile">
			<div className="auth-layer-mobile__wrapper">
				<HomeBackgroundMobile
					image={home.imageMobile}
					thumbnail={home.imageMobileThumbnail}
				/>

				<HomeHeaderContainer />
				<div className="auth-layer__main">
					<div className="auth-layer__marketing-text">
						<HomeMarketingMobile image={home.textMobile} />
					</div>

					<AuthLayerContainer
						isDefaultSignin={IS_DEFAULT_SIGN_IN}
						onAuthSuccess={onAuthSuccess}
						showMarketingBlock={false}
					/>
				</div>
				{showAtolAbta && (
					<div className="auth-layer-mobile__certifications">
						<IconAtolProtected height={40} width={40} />
						<IconABTACertification height={40} width={70} noColor={true} />
					</div>
				)}
			</div>
			<FooterContainer />
		</div>
	);
};

AuthLayerMobile.propTypes = {
	onAuthSuccess: PropTypes.func,
	showAtolAbta: PropTypes.bool,
	home: PropTypes.object,
};

export default memo(AuthLayerMobile);
