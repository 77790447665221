import { composeValidators, hasLengthGreaterThan, isRequired, isRequiredIf } from "revalidate";
import {
	isCreditCardTypeAccepted,
	isCreditCardValid,
	isValidEmail,
	maxPassengersNumber,
	mustNotEqualZero,
	uniqueFullNameValidator,
} from "app/utils/form/validators.js";
import { determineCardType } from "app/utils/creditCardUtils";
import { CREDIT_CARDS_TYPES } from "app/constants";
import { isValidPhone } from "app/utils/form/validators";

export const passwordFieldValidator = composeValidators(
	isRequired({
		message: { id: "error.password.required" },
	}),
	hasLengthGreaterThan(5)({
		message: { id: "error.password.min.length" },
	})
)("password");

export const emailFieldValidator = composeValidators(
	isRequired({
		message: { id: "error.email.required" },
	}),
	isValidEmail({
		message: { id: "error.email.format" },
	})
)("email");

export const firstnameValidator = composeValidators(
	isRequired({
		message: { id: "error.firstName.required" },
	}),
	hasLengthGreaterThan(1)({
		message: { id: "error.firstName.minlength" },
	}),
	uniqueFullNameValidator({
		message: { id: "error.passengers.unique.invalid" },
	})
)("firstname");

export const lastnameValidator = composeValidators(
	isRequired({
		message: { id: "error.lastName.required" },
	}),
	hasLengthGreaterThan(1)({
		message: { id: "error.lastName.minlength" },
	}),
	uniqueFullNameValidator({
		message: { id: "error.passengers.unique.invalid" },
	})
)("lastname");

export const creditCardValidator = (creditCardTypes = []) =>
	composeValidators(
		isRequiredIf(({ number }) => {
			return determineCardType(number) !== CREDIT_CARDS_TYPES.MAESTRO;
		})({
			message: { id: "error.payment.card.number.required" },
		}),
		isCreditCardTypeAccepted(creditCardTypes)({
			message: { id: "error.payment.credit.card.type.not.accepted.new" },
		}),
		isCreditCardValid()({
			message: { id: "error.payment.credit.card.invalid" },
		})
	)("credit card");

export const adultPassengerNumberValidator = composeValidators(
	mustNotEqualZero({
		message: { id: "error.search.engine.adults.min" },
	}),
	maxPassengersNumber("infants", "children")({
		message: { id: "error.search.engine.max.passengers" },
	})
)("adult");

export const phoneFieldValidator = composeValidators(
	isRequired({
		message: { id: "error.phone.required" },
	}),
	isValidPhone({
		message: { id: "error.phone.invalid" },
	})
)("phone");
