import { PureComponent } from "react";
import "./search-booking.scss";
import { propTypes } from "redux-form";
import Input from "app/pages/.shared/form/Input";
import Button from "app/pages/.shared/form/Button";
import PopoverButton from "app/pages/.shared/Popover/PopoverButton";
import { FormattedMessage } from "react-intl";
import { anyFieldTouched } from "app/utils/form-validator";
import Popover from "app/pages/.shared/Popover";
import PropTypes from "prop-types";

class SearchBookingForm extends PureComponent {
	render() {
		const {
			fields = {},
			handleSubmit,
			submitting,
			error,
			resetFormError,
			onClickRecover,
			disabledRecoverData,
		} = this.props;

		return (
			<div className="search-booking-form">
				<h3 className="search-booking-form__title">
					<FormattedMessage id="corporate.search.booking.form.title" />
				</h3>
				<div className="search-booking-form__title-separator" />

				<form
					noValidate
					className="search-booking-form__form"
					onSubmit={handleSubmit}
					data-testid="auth-layer"
				>
					<div className="search-booking-form__input">
						<Input
							field={fields.bookingReference}
							id="search-booking-reference"
							label={
								<FormattedMessage id="corporate.search.booking.form.reference.placeholder" />
							}
							name="search-booking-reference"
							data-testid="booking-reference-input"
						/>
						<div className="search-booking-form__info-picto">
							<PopoverButton
								popOverClassName="search-booking-form__popover"
								popOverPosition="above"
								popOverContent={
									<div className="search-booking-form__help">
										<FormattedMessage
											id="corporate.search.booking.form.reference.help.text"
											values={{ div: chunks => <div>{chunks}</div> }}
										/>
									</div>
								}
								buttonLabel={<i className="icon icon--info-white" />}
							/>
						</div>
					</div>
					<div className="search-booking-form__input">
						<Input
							field={fields.email}
							id="search-booking-email"
							label={
								<FormattedMessage id="corporate.search.booking.form.email.placeholder" />
							}
							name="search-booking-email"
							type="email"
							data-testid="email-input"
						/>
						<div className="search-booking-form__info-picto">
							<PopoverButton
								popOverClassName="search-booking-form__popover"
								popOverPosition="above"
								popOverContent={
									<div className="search-booking-form__help">
										<FormattedMessage id="corporate.search.booking.form.email.help.text" />
									</div>
								}
								buttonLabel={<i className="icon icon--info-white" />}
							/>
						</div>
					</div>

					{!disabledRecoverData && (
						<div className="search-booking-form__link" onClick={onClickRecover}>
							<FormattedMessage id="corporate.search.booking.form.data.lost.label" />
						</div>
					)}

					<div className="search-booking-form__button">
						<Button
							submit
							variant="primary"
							loading={submitting}
							data-testid="search-button"
						>
							<span style={{ textTransform: "uppercase" }}>
								<FormattedMessage id="corporate.search.booking.form.button.text" />
							</span>
						</Button>
						<Popover onClose={resetFormError} isOpen={anyFieldTouched(fields) && error}>
							{error ? <FormattedMessage {...error} /> : ""}
						</Popover>
					</div>
				</form>
			</div>
		);
	}
}

SearchBookingForm.propTypes = {
	...propTypes,
	disabledRecoverData: PropTypes.bool,
	onClickRecover: PropTypes.func.isRequired,
};

export default SearchBookingForm;
