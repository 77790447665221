import { FETCH_LANDING_PAGES_AUTH_SUCCESS } from "app/pages/LandingPage/Auth/LandingAuthActionTypes";

const INITIAL_STATE = {
	landingPages: [],
	activeLandingPageCode: undefined,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_LANDING_PAGES_AUTH_SUCCESS: {
			return { ...state, landingPages: action.res.data };
		}
		default: {
			return state;
		}
	}
};
