import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAllSwitchableShops, getSwitchableShops } from "app/reducers/shopSelector";

const ShopSwitcherContainer = props => {
	return typeof props.renderComponent === "function" && props.renderComponent(props);
};

ShopSwitcherContainer.propTypes = {
	shouldGetAllShops: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
	return {
		currentShop: state.shop,
		shops: props.shouldGetAllShops ? getAllSwitchableShops(state) : getSwitchableShops(state),
	};
};

export default connect(mapStateToProps)(ShopSwitcherContainer);
