import * as Sentry from "@sentry/react";
import { IS_PRODUCTION } from "app/constants";
import { AUTH_SUCCESS } from "app/actionTypes";
import { REHYDRATE } from "redux-persist/constants";
import { isServerSide } from "app/utils/utils";

const SENTRY_DSN = "https://3a89c0583399426f91163c2800e8b27b@o322825.ingest.sentry.io/1820916";

export const sentryMiddleware = store => {
	return next => action => {
		if (action.type === AUTH_SUCCESS || action.type === REHYDRATE) {
			enhanceBugReportWithUserdata(store);
		}
		return next(action);
	};
};

export const initializeSentry = state => {
	const environment = state.envVars.ENVIRONMENT;
	const dsn = SENTRY_DSN;

	if (!isServerSide && environment.includes("live")) {
		Sentry.init({
			dsn,
			environment: environment,
			beforeBreadcrumb(breadcrumb) {
				return breadcrumb.category === "xhr" &&
					breadcrumb.data.url ===
						"https://x6pc9ierpi.execute-api.eu-west-1.amazonaws.com/live"
					? null
					: breadcrumb;
			},
			ignoreErrors: [
				"Non-Error exception captured",
				"Non-Error promise rejection captured",
				"Loading chunk",
				"Loading CSS chunk",
			],
		});

		Sentry.configureScope(scope => {
			scope.setTag("pfs-brand", state.brand.code);
			scope.setTag("pfs-partnercode", state.partner.code);
			scope.setTag("pfs-shop", state.shop);

			scope.addEventProcessor(event => {
				// ignore exception coming from gtm
				try {
					for (const frame of event.exception.values[0].stacktrace.frames) {
						if (
							frame.filename.includes("gtm") ||
							frame.filename.includes("otBannerSdk") ||
							frame.filename.includes("abtasty")
						) {
							return null;
						}
					}
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error("Sentry addEventProcessor exception", err);
				}

				// ignore safari 9
				if (
					window.navigator.userAgent.indexOf(
						"AppleWebKit/601.1.46 (KHTML, like Gecko) Version/9.0"
					) !== -1
				) {
					return null;
				}

				return event;
			});
		});
	}
};

let enhanced = false;

const enhanceBugReportWithUserdata = store => {
	if (enhanced === true) {
		return;
	}

	const state = store.getState();
	let data = {};

	data.uuid = state.auth.uuid;
	data.id = data.uuid;

	// https://docs.sentry.io/platforms/javascript/#capturing-the-user
	if (!data.email && !data.id && !data.username && !data.ip_address && !IS_PRODUCTION) {
		// eslint-disable-next-line no-console
		console.log(
			"Afin de pouvoir identifier un utilisateur dans un rapport de bug, il faut qu'il y ait impérativement au moins une de ces clés : email, id, username, ip_address"
		);
		return;
	}

	if (data.id) {
		Sentry.setUser(data);
		enhanced = true;
	}
};
