import { FETCH_SEARCH_MY_BOOKING_FAILURE, FETCH_SEARCH_MY_BOOKING_SUCCESS } from "app/actionTypes";
import { SEARCH_MY_BOOKINGS_STATUS } from "app/constants";

const initialSearchMyBooking = {
	status: SEARCH_MY_BOOKINGS_STATUS.LOADING,
	booking: undefined,
	timestamp: undefined,
};

export default function searchMyBookingReducer(searchMyBooking = initialSearchMyBooking, action) {
	switch (action.type) {
		case FETCH_SEARCH_MY_BOOKING_SUCCESS:
			return {
				...searchMyBooking,
				status: action.res.data.status,
				booking: action.res.data.booking,
				timestamp: action.res.data.timestampISO8601,
			};

		case FETCH_SEARCH_MY_BOOKING_FAILURE:
			return {
				...searchMyBooking,
				status: SEARCH_MY_BOOKINGS_STATUS.NOT_FOUND,
			};
		default:
			return searchMyBooking;
	}
}
