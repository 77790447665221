import { memo } from "react";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconLightning from "app/pages/.shared/IconLightning";
import { FormattedMessage } from "react-intl";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";
import { IconHelp } from "app/pages/.shared/static/icons";
import "./SmartDPFooterMenu.scss";

const SmartDPFooterMenu = ({ onItemClick = () => {} }) => {
	return (
		<>
			<RelativeLink
				to={{ pathname: "/listing" }}
				className="smart-dp-footer__flashsale"
				data-testid="smart-dp-footer-flashsale-item"
				onClick={onItemClick}
			>
				<IconLightning />
				<FormattedMessage id="sdp.menu.item.flashsales" tagName="h4" />
			</RelativeLink>

			<RelativeLink
				to={{ pathname: "/" }}
				className="smart-dp-footer__item"
				data-testid="smart-dp-footer-search-trip-item"
				onClick={onItemClick}
			>
				<SvgIconMagnifyingGlassBold
					height={20}
					width={20}
					style={{ marginRight: "18px" }}
				/>
				<FormattedMessage id="sdp.menu.item.search.trip" tagName="h4" />
			</RelativeLink>

			<RelativeLink
				className="smart-dp-footer__item"
				to={{ pathname: "/faq" }}
				rel="noopener noreferrer"
				target="_blank"
				data-testid="smart-dp-footer-help-item"
				onClick={onItemClick}
			>
				<IconHelp height={20} width={20} />
				<FormattedMessage id="menu.item.help" tagName="h4" />
			</RelativeLink>
		</>
	);
};

SmartDPFooterMenu.propTypes = {
	onItemClick: PropTypes.func,
};

export default memo(SmartDPFooterMenu);
