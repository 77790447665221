import { UPDATE_MARKETING_DATA } from "app/actionTypes";
import get from "lodash/get";

const initialState = {
	source: undefined,
	marketingOperationCode: undefined,
};

export default (marketing = initialState, action) => {
	switch (action.type) {
		case UPDATE_MARKETING_DATA: {
			let marketingData = {};

			if (get(action, "data.source")) {
				marketingData.source = action.data.source;
			}

			if (get(action, "data.marketingOperationCode")) {
				marketingData.marketingOperationCode = action.data.marketingOperationCode;
			}

			return {
				...marketing,
				...marketingData,
			};
		}
		default:
			return marketing;
	}
};
