import { createSelector } from "reselect";
import without from "lodash/without";
import flatten from "lodash/flatten";
import { BRANDS, SHOP_COUNTRY_LABELS } from "app/constants";

const getBrandCode = state => state.brand.code;
const getBrandShops = state => state.brand.shops;

export const getCurrentShop = state => state.shop;

export const getSwitchableShops = createSelector(
	[getBrandCode, getBrandShops, getCurrentShop],
	(brandCode, shops = [], currentShop) => {
		// on veut masquer de-DE pour la collection air france. A supprimer quand AFIT et AFES seront disponibles
		let switchableShops = flatten(
			shops.map(shop => {
				const locales = shop.locales;

				return locales.map(locale => {
					return `${locale}-${shop.sellingCountry}`;
				});
			})
		);

		if (brandCode === BRANDS.AF && currentShop !== "es-ES" && currentShop !== "it-IT") {
			switchableShops = without(switchableShops, "it-IT", "es-ES");
		}

		return switchableShops.map(shopCode => {
			return {
				code: shopCode,
				label: SHOP_COUNTRY_LABELS[shopCode],
			};
		});
	}
);

export const getAllSwitchableShops = createSelector(
	[getBrandCode, getBrandShops],
	(brandCode, shops = []) => {
		let switchableShops = flatten(
			shops.map(shop => {
				const locales = shop.locales;

				return locales.map(locale => {
					return `${locale}-${shop.sellingCountry}`;
				});
			})
		);

		return switchableShops.map(shopCode => {
			return {
				code: shopCode,
				label: SHOP_COUNTRY_LABELS[shopCode],
			};
		});
	}
);
