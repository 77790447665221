import { sendTagOnPrebookFail, sendTagOnPriceChanged } from "app/utils/analytics";
import { HTTP_STATUS_CODES, PRE_BOOK_CODE_STATUS, STRATEGY } from "app/constants";
import get from "lodash/get";

/**
 * Find the dates that are shared between more than two activities.
 *
 * @param {string[]} activities refers to state.booking.activities
 * @returns {string[]} duplicate dates between activities
 */

export const computeActivitiesDuplicateDates = (activities = []) => {
	let duplicateDates = [];

	for (let i = 0; i < activities.length; i++) {
		for (let j = i + 1; j < activities.length; j++) {
			if (activities[i].date === activities[j].date) {
				const duplicate = activities[i].date;
				if (duplicate && !duplicateDates.includes(duplicate)) {
					duplicateDates.push(duplicate);
				}
			}
		}
	}

	return duplicateDates;
};

export const handleSuccesPreBookResponse = (response, history, isPaymentPage = false, matchUrl) => {
	// TODO gérer les différents de STATUS
	const status = response.data.status;

	const isPreBookFull = [
		PRE_BOOK_CODE_STATUS.FULL_FLIGHT,
		PRE_BOOK_CODE_STATUS.FULL_PROPERTY,
		PRE_BOOK_CODE_STATUS.FULL_TRANSFER,
	].includes(status);

	if (isPreBookFull) {
		history.replace(`${matchUrl}/search`, {
			alertMessageId: "error.no.availabilities.search",
		});
	} else if (status === PRE_BOOK_CODE_STATUS.SUCCESS) {
		if (!isPaymentPage) {
			history.push({
				pathname: `${matchUrl}/payment`,
			});
		}
	} else if (status === PRE_BOOK_CODE_STATUS.WARNING) {
		const { newPrice, oldPrice } = response.data.priceChange;

		if (!isPaymentPage) {
			history.push({
				pathname: `${matchUrl}/payment`,
				state: {
					priceChanged: true,
					newPrice,
					oldPrice,
				},
			});
		}

		sendTagOnPriceChanged(newPrice, oldPrice);
	}
};

export const handleFailPreBookResponse = (response, history, strategy, location) => {
	const httpStatus = response.status;
	const httpErrorMessage = get(response, "data.message");

	if (
		!httpStatus ||
		httpStatus === HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR ||
		httpStatus === HTTP_STATUS_CODES.GATEWAY_TIMEOUT ||
		httpStatus === HTTP_STATUS_CODES.BAD_REQUEST
	) {
		history.push({
			pathname: "/technical-error",
			state: {
				errorMessage: httpErrorMessage,
			},
		}); // push car on veut que le back navigateur retourne sur le page de quotation
	} else if (httpStatus === HTTP_STATUS_CODES.FORBIDDEN) {
		if (strategy === STRATEGY.OAUTH) {
			history.push({ pathname: "/technical-error/sso" });
		} else if (strategy === STRATEGY.TRANSACTION_FIRST) {
			history.replace({
				pathname: "/technical-error",
				state: {
					errorMessage: httpErrorMessage,
				},
			});
		} else {
			history.replace({
				pathname: "/booking/auth",
				search: `?onauthsuccess=${location.pathname}`,
			}); // replace pour éviter un back navigateur sur la page de quotation
		}
	}
	sendTagOnPrebookFail();
	// La gestion du SESSION_EXPIRED est fait dans Quotation.jsx
};

export const calculateFlexIncluded = (insurance, price, nbPassengers) => {
	const pricePerPerson = Math.floor(price / nbPassengers);
	let flexIncluded = 0;
	if (insurance && insurance.priceRanges) {
		const priceRange = insurance.priceRanges.find(
			range => range.min <= pricePerPerson && range.max >= pricePerPerson
		);
		if (priceRange) {
			flexIncluded = Math.floor(
				price * (priceRange.percentagePrice / 100) + nbPassengers * priceRange.absolutePrice
			);
		}
	}

	return flexIncluded;
};
