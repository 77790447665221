import { memo } from "react";
import PropTypes from "prop-types";

const HomeMarketing = ({ image }) => {
	return (
		<div className="home-marketing">
			<img src={image} />
		</div>
	);
};

HomeMarketing.propTypes = {
	image: PropTypes.string,
};

export default memo(HomeMarketing);
