import PropTypes from "prop-types";
import values from "lodash/values";

import {
	ALERT_TYPE,
	BRANDS,
	CREDIT_CARDS_TYPES,
	MEDIA_TYPE,
	OFFER_PRICE_TYPES,
	OFFER_TYPES,
	PAYMENT_METHODS,
	PRODUCT_CATEGORY_TYPES,
	QUOTATION_ITEMS_TYPE,
	RESOLUTION,
	STRATEGY,
	VIDEO_AUTOPLAY,
} from "app/constants";

export const messagePropType = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.object,
	PropTypes.array,
]);

export const xyAxisPropTypes = PropTypes.oneOf(["x", "X", "y", "Y"]);

export const socialNetworksLinksShape = PropTypes.shape({
	instagram: PropTypes.string,
	facebook: PropTypes.string,
});

export const partnerRedirectionLinkShape = PropTypes.shape({
	url: PropTypes.string,
	label: PropTypes.string,
});

export const quotationItemShape = PropTypes.shape({
	type: PropTypes.oneOf([
		QUOTATION_ITEMS_TYPE.BOOKING_FEES,
		QUOTATION_ITEMS_TYPE.SPECIAL_DISCOUNT,
		QUOTATION_ITEMS_TYPE.TRIP,
	]),
	description: PropTypes.string,
	price: PropTypes.number,
});

export const offerTypePropTypes = PropTypes.oneOf([
	OFFER_TYPES.ACCOMODATION_ONLY,
	OFFER_TYPES.FLIGHT_WITH_ACCOMMODATION,
]);

export const photoPropTypes = PropTypes.shape({
	alt: PropTypes.string,
	inHeader: PropTypes.bool,
	url: PropTypes.string,
	thumbnail: PropTypes.string,
});

export const quotationTransferShape = PropTypes.shape({
	photo: photoPropTypes,
	code: PropTypes.string,
	quantity: PropTypes.number,
	maximumCapacity: PropTypes.number,
	upgradePrice: PropTypes.number,
	vehicule: PropTypes.string,
	description: PropTypes.string,
});

export const videoPropTypes = PropTypes.shape({
	uri: PropTypes.string,
	url: PropTypes.string,
	videoType: PropTypes.string,
	autoplay: PropTypes.oneOf([VIDEO_AUTOPLAY.TRUE, VIDEO_AUTOPLAY.FALSE]),
});

export const landingPageShape = PropTypes.shape({
	code: PropTypes.string,
	text1: PropTypes.string,
	text1FontSize: PropTypes.number,
	text2: PropTypes.string,
	text2FontSize: PropTypes.number,
	bgColor: PropTypes.string,
	textColor: PropTypes.string,
	borderColor: PropTypes.string,
	position: PropTypes.string,
	photoLarge: photoPropTypes,
	photoSmall: photoPropTypes,
});

export const boardShape = PropTypes.shape({
	name: PropTypes.string,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
	moreDetails: PropTypes.arrayOf(PropTypes.string),
	code: PropTypes.string,
});

export const quotationActivityShape = PropTypes.shape({
	code: PropTypes.string,
	name: PropTypes.string,
	guestsPerDay: PropTypes.object,
	suggestedDate: PropTypes.string,
});

export const resolutionPropType = PropTypes.oneOf([
	RESOLUTION.LARGE,
	RESOLUTION.MEDIUM,
	RESOLUTION.SMALL,
	RESOLUTION.UNKNOWN,
	RESOLUTION.XLARGE,
]);

export const articleShape = PropTypes.shape({
	title: PropTypes.string,
	text: PropTypes.string,
	photoUrl: PropTypes.string,
	ctaText: PropTypes.string,
	ctaTextColor: PropTypes.string,
	ctaBackgroundColor: PropTypes.string,
	url: PropTypes.string,
});

export const brandPropTypes = PropTypes.oneOf(values(BRANDS));

export const merchandisingTopic = PropTypes.shape({
	filterValue: PropTypes.string,
	photoLarge: photoPropTypes,
	photoSmall: photoPropTypes,
});

export const merchandisingShape = PropTypes.shape({
	code: PropTypes.string,
	button: PropTypes.shape({
		label: PropTypes.string,
	}),
	label: PropTypes.string,
	position: PropTypes.number,
	headerIndex: PropTypes.number,
	displayMode: PropTypes.oneOf(["list", "card"]),
	photoLarge: photoPropTypes,
	photoSmall: photoPropTypes,
	mainPhotoLarge: photoPropTypes,
	mainPhotoSmall: photoPropTypes,
	merchandisingFilterValues: PropTypes.arrayOf(PropTypes.string),
	merchandisingCategoryFilterValues: PropTypes.arrayOf(PropTypes.string),
	merchandisingTopics: PropTypes.arrayOf(merchandisingTopic),
});

export const priceShape = PropTypes.shape({
	dd: PropTypes.number,
	ed: PropTypes.number,
	o: PropTypes.bool,
	p: PropTypes.number,
	i: PropTypes.shape({
		mp: PropTypes.bool, // meilleur prix
	}),
});

export const callCenterShape = PropTypes.shape({
	officeHours: PropTypes.arrayOf(PropTypes.string),
	callPrice: PropTypes.string,
	displayPhone: PropTypes.arrayOf(PropTypes.string),
	dialPhone: PropTypes.string,
	helpContactUrl: PropTypes.string,
	email: PropTypes.string,
	services: PropTypes.array,
});

export const fromPriceTypeShape = PropTypes.oneOf([
	OFFER_PRICE_TYPES.FROM_PRICE_TYPE_SAVE_UP_TO,
	OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM,
	OFFER_PRICE_TYPES.FROM_PRICE_TYPE_EXCLUSIVE,
	OFFER_PRICE_TYPES.FROM_PRICE_TYPE_FROM_WITH_FLIGHT,
]);

export const fromPriceTypes = {
	mealBasis: PropTypes.string,
	includeOfferWithFlight: PropTypes.bool,
	duration: PropTypes.number,
	type: fromPriceTypeShape,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	publicPrice: PropTypes.number,
	price: PropTypes.number,
};

export const insuranceShape = PropTypes.shape({
	code: PropTypes.string,
	price: PropTypes.number,
	name: PropTypes.string,
	intro: PropTypes.string,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
});

export const insurancesShape = PropTypes.shape({
	intro: PropTypes.string,
	logo: photoPropTypes,
	insuranceItems: PropTypes.arrayOf(insuranceShape),
});

export const usefulInformationShape = PropTypes.shape({
	formalities: PropTypes.shape({
		intro: PropTypes.string,
		identity: PropTypes.string,
		vaccine: PropTypes.string,
		visa: PropTypes.string,
	}),
	formalitiesOut: PropTypes.shape({
		intro: PropTypes.string,
		identity: PropTypes.string,
		vaccine: PropTypes.string,
		visa: PropTypes.string,
	}),
	goodtoKnow: PropTypes.arrayOf(PropTypes.string),
	transport: PropTypes.arrayOf(PropTypes.string),
	travelDocuments: PropTypes.arrayOf(PropTypes.string),
});

export const myBookingShape = {
	status: PropTypes.string,
	bookingReference: PropTypes.string,
	bookingDocument: PropTypes.shape({
		invoice: PropTypes.string,
		voucher: PropTypes.string,
	}),
	bookingDate: PropTypes.string,
	name: PropTypes.string,
	category: PropTypes.number,
	country: PropTypes.string,
	region: PropTypes.string,
	resort: PropTypes.string,
	duration: PropTypes.number,
	departureDate: PropTypes.string,
	endDate: PropTypes.string,
	totalPaid: PropTypes.number,
	totalDue: PropTypes.number,
	total: PropTypes.number,
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
	currencyCode: PropTypes.string,
	brand: brandPropTypes,
	shop: PropTypes.string,
};

export const myBookingsShape = PropTypes.shape({
	pastBookings: PropTypes.arrayOf(PropTypes.shape(myBookingShape)),
	futureBookings: PropTypes.arrayOf(PropTypes.shape(myBookingShape)),
	timestamp: PropTypes.string,
	status: PropTypes.string,
});

export const badgeShape = PropTypes.shape({
	label: PropTypes.string,
	txtColor: PropTypes.string,
	bgColor: PropTypes.string,
});

export const merchShape = PropTypes.shape({
	type: PropTypes.string,
	merchandising: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
});

export const productShape = PropTypes.shape({
	name: PropTypes.string,
	isTopCountry: PropTypes.bool,
	zones: PropTypes.arrayOf(PropTypes.string),
	country: PropTypes.string,
	resort: PropTypes.string,
	region: PropTypes.string,
	highlights: PropTypes.array,
	badges: PropTypes.arrayOf(badgeShape),
	category: PropTypes.number,
	categoryType: PropTypes.oneOf([PRODUCT_CATEGORY_TYPES.TRIDENT, PRODUCT_CATEGORY_TYPES.STAR]),
	marketingDestination: PropTypes.string,
	expiration: PropTypes.shape({
		hour: PropTypes.number,
		day: PropTypes.number,
	}),
	availabilityDate: PropTypes.shape({
		hour: PropTypes.number,
		day: PropTypes.number,
	}),
	fromPriceType: PropTypes.shape(fromPriceTypes),
	type: PropTypes.string,
});

export const transportPartnershipShape = {
	video: PropTypes.shape({ url: PropTypes.string, previewUrl: PropTypes.string }),
	headline: PropTypes.string,
	intro: PropTypes.string,
	logo: photoPropTypes,
	photo: photoPropTypes,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
};

export const expertShape = {
	name: PropTypes.string,
	photo: photoPropTypes,
	introduction: PropTypes.string,
	verbatim: PropTypes.string,
	loves: PropTypes.arrayOf(PropTypes.string),
};

export const activityShape = PropTypes.shape({
	label: PropTypes.string,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
	photo: photoPropTypes,
});

export const ficheProduitPropTypes = PropTypes.shape({
	name: PropTypes.string,
	adultOnly: PropTypes.bool,
	isChildAgeRequired: PropTypes.bool,
	country: PropTypes.string,
	resort: PropTypes.string,
	region: PropTypes.string,
	highlights: PropTypes.array,
	destination: PropTypes.shape({
		label: PropTypes.string,
		photo: photoPropTypes,
		paragraphs: PropTypes.arrayOf(PropTypes.string),
	}),
	transportPartnership: PropTypes.shape(transportPartnershipShape),
	category: PropTypes.number,
	experiences: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			paragraphs: PropTypes.arrayOf(PropTypes.string),
			photo: photoPropTypes,
		})
	),
	tripAdvisor: PropTypes.shape({
		rating: PropTypes.number,
		reviewsCount: PropTypes.number,
	}),
	usefulInformations: usefulInformationShape,
	expiration: PropTypes.shape({
		hour: PropTypes.number,
		day: PropTypes.number,
	}),
	gps: PropTypes.shape({
		lat: PropTypes.number,
		lon: PropTypes.number,
	}),
	activity: PropTypes.shape({
		facilities: PropTypes.arrayOf(PropTypes.string),
		intro: PropTypes.arrayOf(PropTypes.string),
		label: PropTypes.string,
		description: PropTypes.string,
		activities: PropTypes.arrayOf(activityShape),
	}),
	fromPriceType: PropTypes.shape(fromPriceTypes),
	offers: PropTypes.array,
	expert: PropTypes.shape(expertShape),
	included: PropTypes.shape({
		AO: PropTypes.arrayOf(PropTypes.string),
		FA: PropTypes.arrayOf(PropTypes.string),
	}),
	notIncluded: PropTypes.shape({
		AO: PropTypes.arrayOf(PropTypes.string),
		FA: PropTypes.arrayOf(PropTypes.string),
	}),
});

export const tokenInfoShape = PropTypes.shape({
	tokenId: PropTypes.string,
	ip: PropTypes.string,
	userAgent: PropTypes.string,
});

export const legShape = PropTypes.shape({
	operatingAirlineName: PropTypes.string,
	airline: PropTypes.shape({
		name: PropTypes.string,
	}),
	flightNumber: PropTypes.string,
	from: PropTypes.shape({
		airport: PropTypes.string,
		iata: PropTypes.string,
		takeOffDate: PropTypes.string,
		takeOffTime: PropTypes.string,
	}),
	luggage: PropTypes.shape({
		quantity: PropTypes.number,
	}),
	to: PropTypes.shape({
		airport: PropTypes.string,
		iata: PropTypes.string,
		landingDate: PropTypes.string,
		landingTime: PropTypes.string,
		flightDuration: PropTypes.string,
		stopDuration: PropTypes.string,
	}),
});

export const luggageShape = PropTypes.shape({
	included: PropTypes.bool,
	code: PropTypes.string,
	maxWeightPerBagage: PropTypes.number,
	pricePerPerson: PropTypes.number,
	pricePerBooking: PropTypes.number,
	message: PropTypes.string,
	cabinBaggage: PropTypes.shape({
		included: PropTypes.bool,
		message: PropTypes.string,
		maxWeightPerBagage: PropTypes.number,
	}),
});

export const quotationFlightShape = PropTypes.shape({
	code: PropTypes.string,
	flightOptions: PropTypes.shape({
		flightBagageOption: luggageShape,
	}),
	inbound: PropTypes.shape({
		airline: PropTypes.shape({
			name: PropTypes.string,
			code: PropTypes.string,
		}),
		legs: PropTypes.arrayOf(legShape),
		totalDuration: PropTypes.string,
		cabin: PropTypes.string,
	}),
	included: PropTypes.bool,
	tag: PropTypes.string,
	warnings: PropTypes.arrayOf(
		PropTypes.oneOf([
			"WARNING_CONNECTION",
			"WARNING_TRAIN",
			"WARNING_EARLY_INBOUND_DEPARTURE",
			"WARNING_SECONDARY_AIRPORT",
		])
	),
	outbound: PropTypes.shape({
		airline: PropTypes.shape({
			name: PropTypes.string,
			code: PropTypes.string,
		}),
		legs: PropTypes.arrayOf(legShape),
		totalDuration: PropTypes.string,
		cabin: PropTypes.string,
	}),
	upgradePrice: PropTypes.number,
});

export const quotationBoardShape = PropTypes.shape({
	boardCode: PropTypes.string,
	label: PropTypes.string,
	upgradePrice: PropTypes.number,
	paragraphs: PropTypes.arrayOf(PropTypes.string),
});

export const quotationPropTypes = PropTypes.shape({
	accommodations: PropTypes.arrayOf(
		PropTypes.shape({
			included: PropTypes.bool,
			items: PropTypes.array,
			codes: PropTypes.array,
			code: PropTypes.string,
		})
	),
	roomDescriptions: PropTypes.arrayOf(
		PropTypes.shape({
			roomCode: PropTypes.string,
			photo: photoPropTypes,
			description: PropTypes.string,
		})
	),
	boards: PropTypes.arrayOf(quotationBoardShape),
	flights: PropTypes.arrayOf(quotationFlightShape),
});

export const durationShape = PropTypes.shape({
	value: PropTypes.number,
	prices: PropTypes.arrayOf(priceShape),
});

export const bookingPropTypes = PropTypes.shape({
	offer: PropTypes.shape({
		type: PropTypes.oneOf(["AO", "FA"]),
		label: PropTypes.string,
		contractType: PropTypes.oneOf(["Rental", "Hotel"]),
	}),
	departureCity: PropTypes.object,
	price: PropTypes.number,
	publicPrice: PropTypes.number,
	adults: PropTypes.number,
	children: PropTypes.number,
	infants: PropTypes.number,
	duration: durationShape,
	rentalAccommodation: PropTypes.shape({
		accommodationItemCode: PropTypes.number,
		accommodation: PropTypes.string,
		config: PropTypes.string,
		minPrice: PropTypes.shape({
			dd: PropTypes.number,
			ed: PropTypes.number,
			p: PropTypes.number,
			pp: PropTypes.number,
			o: PropTypes.bool,
			n: PropTypes.number,
		}),
	}),
	activities: PropTypes.array,
	options: PropTypes.object,
	bookingUserSelection: PropTypes.object,
	totals: PropTypes.object,
});

export const strategyShape = PropTypes.oneOf([
	STRATEGY.TRANSACTION_FIRST,
	STRATEGY.NAVIGATION_FIRST,
	STRATEGY.SIGNUP_FIRST,
	STRATEGY.OAUTH,
	STRATEGY.NONE,
]);

export const partnerShape = PropTypes.shape({
	enablePromotionCode: PropTypes.bool,
	strategy: strategyShape,
	code: PropTypes.string,
});

export const flightsFilterValuesShape = PropTypes.shape({
	airport: PropTypes.arrayOf(PropTypes.string),
	cabin: PropTypes.arrayOf(PropTypes.string),
	airline: PropTypes.arrayOf(PropTypes.string),
	outboundDuration: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number,
	}),
	inBoundDuration: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number,
	}),
	inboundTakeOffTime: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number,
	}),
	outboundTakeOffTime: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number,
	}),
	upgradePrice: PropTypes.number,
});

export const paymentTypesValues = PropTypes.oneOf([
	PAYMENT_METHODS.CASINO,
	PAYMENT_METHODS.PLEDG,
	PAYMENT_METHODS.ALMA,
	PAYMENT_METHODS.CARD,
	PAYMENT_METHODS.SWISS_BILLING,
	PAYMENT_METHODS.IDEAL,
	PAYMENT_METHODS.POSTFINANCE_CARD,
	PAYMENT_METHODS.POSTFINANCE_EFINANCE,
	PAYMENT_METHODS.BCMC,
]);

export const creditCardTypesValues = PropTypes.arrayOf(
	PropTypes.oneOf([
		CREDIT_CARDS_TYPES.VISA,
		CREDIT_CARDS_TYPES.MASTERCARD,
		CREDIT_CARDS_TYPES.AMEX,
		CREDIT_CARDS_TYPES.MAESTRO,
		CREDIT_CARDS_TYPES.CB,
		CREDIT_CARDS_TYPES.VISA_ELECTRON,
	])
);

export const paymentTypeShape = PropTypes.shape({
	paymentType: paymentTypesValues,
	acceptedCreditCardTypes: creditCardTypesValues,
	percentagePayNow: PropTypes.number,
	dueDate: PropTypes.number,
	terms: PropTypes.number,
});

export const payment4xShape = PropTypes.shape({
	isActive: PropTypes.bool,
	minAmount: PropTypes.number,
	maxAmount: PropTypes.number,
});

export const paymentShape = PropTypes.shape({
	bookStatus: PropTypes.string,
	dateOfBirthRequired: PropTypes.bool,
	payment4x: payment4xShape,
	showFrequentTravellerCardInput: PropTypes.bool,
	paymentTypes: PropTypes.arrayOf(paymentTypeShape),
	insurances: PropTypes.object,
	timestamp: PropTypes.number,
});

export const accommodationTypeShape = {
	accommodationItemCode: PropTypes.number,
	aera: PropTypes.number,
	description: PropTypes.string,
	label: PropTypes.string,
	occupancies: PropTypes.arrayOf(
		PropTypes.shape({
			adults: PropTypes.number,
			children: PropTypes.number,
			infants: PropTypes.number,
		})
	),
	photo: photoPropTypes,
};

export const friendSubscribedShape = PropTypes.shape({
	susbcribeAt: PropTypes.number,
	amount: PropTypes.number,
	maxUseDate: PropTypes.number,
	expired: PropTypes.bool,
	used: PropTypes.bool,
});

export const friendBookedShape = PropTypes.shape({
	amount: PropTypes.number,
	maxUseDate: PropTypes.number,
	expired: PropTypes.bool,
	used: PropTypes.bool,
});

export const sponsorshipCreditShape = PropTypes.shape({
	amount: PropTypes.number,
	maxUseDate: PropTypes.number,
	expired: PropTypes.bool,
	used: PropTypes.bool,
});

export const friendStatus = PropTypes.shape({
	email: PropTypes.string,
	friendSubscribed: friendSubscribedShape,
	friendBooked: friendBookedShape,
});

export const alertTypeProptypes = PropTypes.oneOf([ALERT_TYPE.ERROR]);

export const pricingShape = PropTypes.shape({
	availableDurations: PropTypes.arrayOf(PropTypes.number),
	config: PropTypes.string,
	contractType: PropTypes.string,
	durations: PropTypes.arrayOf(
		PropTypes.shape({
			maxDiscount: priceShape,
			minPrice: priceShape,
			value: PropTypes.number,
		})
	),
	label: PropTypes.string,
	maxDiscount: priceShape,
	minPrice: priceShape,
	type: PropTypes.string,
});

export const offerShape = PropTypes.shape({
	negos: PropTypes.arrayOf(PropTypes.string),
	pricing: pricingShape,
});

export const navLogShape = PropTypes.shape({
	date: PropTypes.string,
	propertyReferenceID: PropTypes.number,
	quotationURL: PropTypes.string,
});

export const mediaTypes = PropTypes.oneOf(values(MEDIA_TYPE));

export const merchandisingMediaShape = PropTypes.shape({
	copyright: PropTypes.string,
	description: PropTypes.arrayOf(PropTypes.string),
	image: PropTypes.string,
	thumbnail: PropTypes.string,
	title: PropTypes.string,
	video: PropTypes.shape({
		videoUrl: PropTypes.string,
		videoType: PropTypes.string,
		thumbnail: PropTypes.string,
		url: PropTypes.string,
	}),
});

export const landingPageAuthShape = PropTypes.shape({
	code: PropTypes.string,
	photoLargeBackgroundUrl: PropTypes.string,
	photoLargeBackgroundThumbnail: PropTypes.string,
	photoLargeTextUrl: PropTypes.string,
	photoLargeTextThumbnail: PropTypes.string,
	photoSmallBackgroundUrl: PropTypes.string,
	photoSmallBackgroundThumbnail: PropTypes.string,
	photoSmallTextUrl: PropTypes.string,
	photoSmallTextThumbnail: PropTypes.string,
	productsUriUrl: PropTypes.arrayOf(PropTypes.string),
	backgroundPosition: PropTypes.string,
	photoSmallBackgroundHeight: PropTypes.string,
	isHeaderImmersive: PropTypes.bool,
	isLogoWhite: PropTypes.bool,
});

export const quotationErrorShape = PropTypes.shape({
	duplicateDates: PropTypes.arrayOf(PropTypes.string),
});
