import { combineValidators } from "revalidate";
import { mustAcceptTerms } from "../../../utils/form/validators";
import { emailFieldValidator, passwordFieldValidator } from "app/utils/form/fieldValidators";

export const fields = ["email", "password", "terms"];

export const validate = combineValidators({
	email: emailFieldValidator,
	password: passwordFieldValidator,
	terms: mustAcceptTerms({
		message: { id: "error.must.accept.terms" },
	}),
});
