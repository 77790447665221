/* eslint-disable consistent-return */
import { createValidator } from "revalidate";
import { determineCardType, validateCreditCardData } from "app/utils/creditCardUtils.js";
import forOwn from "lodash/forOwn";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import { MAX_PASSENGERS_ALLOWED, PAYMENT_METHODS } from "app/constants";
import get from "lodash/get";
import { validatePhoneIntl } from "app/utils/utils";

const CREDIT_CARD_ERROR_MAP = {
	cvc: "CVC incorrect",
	holderName: "holderName incorrect",
	month: "Mois incorrect",
	year: "Annee incorrecte",
	expiryYear: "Année expiration incorrecte",
	expiryMonth: "Mois expiration incorrecte",
	number: "Numero de carte incorrecte",
	luhn: "Validation Lhun incorrecte",
};

export const isAmount = createValidator(
	message => value => {
		let valueToValidate = value;
		if (value && typeof value === "string") {
			valueToValidate = value.replace(",", ".");
		}

		if ((valueToValidate && isNaN(valueToValidate)) || valueToValidate < 0) {
			return message;
		}
	},
	"The amount is invalid."
);

export const isLessThan = max =>
	createValidator(
		message => value => {
			if (value && Number(value) > max) {
				return message;
			}
		},
		"Amount must be less than or equal amount to pay."
	);

export const isValidEmail = createValidator(
	message => value => {
		if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
			return message;
		}
	},
	"Please check the format of your email."
);

export const mustAcceptTerms = createValidator(
	message => value => {
		if (!value) {
			return message;
		}
	},
	"Your must accept the PerfectStay privacy policy."
);

export const isCreditCardTypeAccepted = (acceptedCreditCardTypes = []) => {
	return createValidator(
		message => value => {
			const creditCardType = determineCardType(value);

			if (!includes(acceptedCreditCardTypes, creditCardType)) {
				return message;
			}
		},
		"The credit card is invalid. Please check the number, expiration date and cvc."
	);
};

export const isCreditCardValid = ({ isOnNumberChange = true } = {}) => {
	return createValidator(
		message => (value, allValues) => {
			let creditCardData;
			if (allValues.payment.type === PAYMENT_METHODS.BCMC) {
				creditCardData = {
					number: isOnNumberChange ? value : get(allValues, "payment.cardBCMC.number"),
					expiryMonth: get(allValues, "payment.cardBCMC.expiryMonth"),
					expiryYear: "20" + get(allValues, "payment.cardBCMC.expiryYear"),
					holderName: get(allValues, "payment.cardBCMC.holderName"),
				};
			} else {
				creditCardData = {
					number: isOnNumberChange ? value : get(allValues, "payment.card.number"),
					cvc: get(allValues, "payment.card.cvc"),
					expiryMonth: get(allValues, "payment.card.expiryMonth"),
					expiryYear: "20" + get(allValues, "payment.card.expiryYear"),
					holderName: get(allValues, "payment.card.holderName"),
				};
			}

			let adyenErrors = validateCreditCardData(creditCardData);
			let errors = {};

			forOwn(adyenErrors, (value, key) => {
				if (value === false) {
					errors[key] = CREDIT_CARD_ERROR_MAP[key];
				}
			});

			if (!isEmpty(errors)) {
				return message;
			}
		},
		"The credit card is invalid. Please check the number, expiration date and cvc."
	);
};

export const babiesNumberMustNotExceedAdultsNumber = adults => {
	return createValidator(
		message => (value, allValues) => {
			if (value > allValues[adults]) {
				return message;
			}
		},
		"The number of babies must not exceed the number of adults."
	);
};

export const maxPassengersNumber = (infants, children) => {
	return createValidator(
		message => (value, allValues) => {
			const passengersCount =
				Number(value) + Number(allValues[infants]) + Number(allValues[children]);
			if (passengersCount > MAX_PASSENGERS_ALLOWED) {
				return message;
			}
		},
		"The number of passengers must not exceed 9."
	);
};

export const childrenAge = createValidator(
	message => value => {
		if (value < 0 || value > 18) {
			return message;
		}
	},
	"The child must age between 0 and 18."
);

export const mustNotEqualZero = createValidator(
	message => value => {
		// eslint-disable-next-line eqeqeq
		if (value == 0) {
			return message;
		}
	},
	"The credit card is invalid. Please check the number, expiration date and cvc."
);

export const customIsRequiredIf = createValidator(
	message => value => {
		// Si utilisé pour un mois de naissance, janvier aura pour valeur 0
		// Il faut que la valeur 0 soit une valeur valide
		if (value !== "novalidate" && (value === undefined || value === null || value === "")) {
			return message;
		}
	},
	field => `${field} is required`
);

export const uniqueFullNameValidator = createValidator(
	message => (lastName, allValues) => {
		if (
			allValues.passengers &&
			allValues.passengers.filter(
				passenger =>
					passenger.name.fullName === allValues.fullName &&
					passenger.index < allValues.index
			).length > 0
		) {
			return message;
		}
	},
	"The full name must be unique."
);

export const isValidPhone = createValidator(
	message => phoneNumberIntl => {
		const isMatching = validatePhoneIntl(phoneNumberIntl);

		if (!isMatching) {
			return message;
		}
	},
	"The number is invalid for the country selected."
);
