import { memo, useCallback, useContext, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import { useHistory, useLocation } from "react-router-dom";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { AsyncSmartDPSearchFormContainer } from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import "./SmartDPHeader.scss";
import { BRANDS } from "app/constants";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { AsideMenuAccountContainer } from "app/pages/.shared/AsideMenu/AsideMenuAccountContainer";
import HamburgerAccount from "app/pages/.shared/HamburgerAccount/HamburgerAccount";
import SmartDPMenuUnauthenticatedContainer from "app/pages/.shared/SmartDPMenuUnauthenticated/SmartDPMenuUnauthenticatedContainer";
import SmartDPMenuAuthenticatedContainer from "app/pages/.shared/SmartDPMenuAuthenticated/SmartDPMenuAuthenticatedContainer";
import { useAnalytics } from "app/utils/analytics/useAnalytics";
import {
	offset,
	safePolygon,
	shift,
	useFloating,
	useHover,
	useInteractions,
} from "@floating-ui/react-dom-interactions";

const SmartDPHeader = ({
	displayBackButton,
	isLogoSmall,
	operatorEmail,
	resetAllSDPProductsFilter = () => {},
	userIsConnected,
	isWhiteLabel,
}) => {
	const [open, setOpen] = useState(false);
	const [isSideMenuOpened, toggleSideMenu] = useState(false);
	const { track } = useAnalytics();
	const { pathname } = useLocation();
	const history = useHistory();
	const { resolution, brand } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const isBrandEH = brand === BRANDS.EH || brand === BRANDS.EK;

	const logoClass = classNames("smart-dp-header__logo", {
		"smart-dp-header__logo--small": isLogoSmall,
	});

	const headerClass = classNames("smart-dp-header", { "smart-dp-header--eh": isBrandEH });

	const handleFormSubmission = useCallback((data, path) => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		if (path === "/sdp/listing") {
			history.replace({ pathname: "/sdp/listing", search: queryParams });
		} else {
			history.push({ pathname: "/sdp/listing", search: queryParams });
		}
	}, []);

	const openSideMenu = useCallback(() => {
		toggleSideMenu(!isSideMenuOpened);
		track("member_access");
	}, [isSideMenuOpened]);

	const closeSideMenu = useCallback(() => {
		toggleSideMenu(false);
	}, []);

	const showAccountMenu =
		!pathname.includes("booking-search") &&
		!pathname.includes("booking-auth") &&
		!pathname.includes("booking/auth") &&
		!pathname.includes("booking/quote") &&
		!pathname.includes("booking/payment") &&
		!pathname.includes("booking/confirmation") &&
		(isWhiteLabel || userIsConnected);

	const disabledHomeRedirect =
		pathname.includes("booking/quote") || pathname.includes("booking/payment");

	const { context, x, y, reference, floating, strategy } = useFloating({
		placement: "bottom",
		strategy: "absolute",
		open,
		onOpenChange: setOpen,
		middleware: [offset(), shift()],
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		useHover(context, {
			mouseOnly: true,
			handleClose: safePolygon(),
		}),
	]);

	const closeMenu = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<header className={headerClass}>
			<div className="smart-dp-header__container">
				{displayBackButton && (
					<div className="smart-dp-header__back">
						<BackButton forceBrowserBack={true} />
					</div>
				)}
				<div className={logoClass}>
					{disabledHomeRedirect ? (
						<LogoBrandContainer />
					) : (
						<RelativeLink to={{ pathname: "/" }} data-testid="header-back-link">
							<LogoBrandContainer />
						</RelativeLink>
					)}
				</div>
				{(pathname === "/sdp/listing" || pathname === "/sdp/booking/quote") && !isMobile && (
					<div className="smart-dp-header__form">
						<AsyncSmartDPSearchFormContainer onSuccess={handleFormSubmission} />
					</div>
				)}
				{showAccountMenu ? (
					<div className="smart-dp-header__account-menu">
						{isMobile ? (
							<div className="header__hamburger">
								<HamburgerAccount onToogle={openSideMenu} />
								<SidePanel
									isOpen={isSideMenuOpened}
									direction={"rtl"}
									onClose={closeSideMenu}
								>
									<AsideMenuAccountContainer onClose={closeSideMenu} />
								</SidePanel>
							</div>
						) : (
							<>
								<div
									className="smart-dp-header__popover-button"
									{...getReferenceProps({ ref: reference })}
								>
									<HamburgerAccount />
								</div>
								{open && (
									<>
										<div
											className="smart-dp-header__dropdown"
											{...getFloatingProps({
												ref: floating,
												style: {
													position: strategy,
													left: x ?? "",
													top: y ?? "",
												},
											})}
										>
											{userIsConnected ? (
												<SmartDPMenuAuthenticatedContainer
													onItemClick={closeMenu}
												/>
											) : (
												<SmartDPMenuUnauthenticatedContainer />
											)}
										</div>
									</>
								)}
							</>
						)}
					</div>
				) : (
					<div />
				)}
				{operatorEmail && (
					<div className="smart-dp-header__operator">
						<strong>Agent :</strong> {operatorEmail}
					</div>
				)}
			</div>
		</header>
	);
};

SmartDPHeader.propTypes = {
	displayBackButton: PropTypes.bool,
	isLogoSmall: PropTypes.bool,
	operatorEmail: PropTypes.string,
	isWhiteLabel: PropTypes.bool,
	userIsConnected: PropTypes.bool,
	resetAllSDPProductsFilter: PropTypes.func,
	shouldDisplayCreditNotesEntry: PropTypes.bool,
	enableCredits: PropTypes.bool,
	creditNotesAmount: PropTypes.number,
	creditsAmount: PropTypes.number,
};

export default memo(SmartDPHeader);
