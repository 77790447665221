import get from "lodash/get";
import range from "lodash/range";
import { PASSENGER_TYPE } from "app/constants";
import subHours from "date-fns/subHours";
import { stringify } from "qs";
import { sendTagOnSDPSearch } from "app/utils/analytics";

export const buildSDPQuotePayload = (queryParams, partnerCode) => {
	const requestBody = {
		departureCityCode: queryParams.departureCityCode,
		destinationResort: Number(queryParams.destinationResortCode),
		departureDate: Number(queryParams.departureDate),
		duration: Number(queryParams.duration),
		cabin: queryParams.cabinCode,
		partnerCode,
	};
	const newOccupancies =
		queryParams.occupancies &&
		queryParams.occupancies.map(occupancy => {
			if (occupancy.childrenBirthdates) {
				const childrenBirthdates = occupancy.childrenBirthdates.map(childrenBirthdate => {
					return {
						day: Number(childrenBirthdate.day),
						month: Number(childrenBirthdate.month) + 1,
						year: Number(childrenBirthdate.year),
					};
				});
				return {
					adults: Number(occupancy.adults),
					children: Number(occupancy.children),
					childrenBirthdates,
				};
			}
			return { adults: Number(occupancy.adults) };
		});

	requestBody.occupancies = newOccupancies;
	return requestBody;
};

export const getFlightNegotiatedLabelKey = (flight = {}) => {
	const isDirectFlight =
		get(flight, "outbound.legs.length", 0) === 1 && get(flight, "inbound.legs.length", 0) === 1;

	const isLuggageIncluded = get(flight, "flightOptions.flightBagageOption.included") === true;

	let flightNegociatedLabel = "sdp.listing.product.flight.negotiated.label";

	if (isDirectFlight && !isLuggageIncluded) {
		flightNegociatedLabel = "sdp.listing.product.direct.flight.negotiated.label";
	} else if (!isDirectFlight && isLuggageIncluded) {
		flightNegociatedLabel = "sdp.listing.product.luggage.flight.negotiated.label";
	} else if (isDirectFlight && isLuggageIncluded) {
		flightNegociatedLabel = "sdp.listing.product.direct.luggage.flight.negotiated.label";
	}

	return flightNegociatedLabel;
};

export function buildSDPBudgetFilterValues(accommodationsList, basePrice, travellersTotalCount) {
	const allPrices = accommodationsList
		.map(accommodation => {
			return getAccommodationPrice(accommodation, basePrice, travellersTotalCount);
		})
		.filter(_ => _)
		.sort((a, b) => a - b);

	// Quartiles et on regarde si c'est la même valeur alors on arrondi à 50 supérieur
	const q1 = round50(getPercentile(allPrices, 25));
	const median = round50(getPercentile(allPrices, 50));
	const q3 = round50(getPercentile(allPrices, 75));

	const isOneSameFilter = q1 === median || median === q3;

	return isOneSameFilter
		? []
		: [{ min: 0, max: q1 }, { min: q1, max: median }, { min: median, max: q3 }, { min: q3 }];
}

export function getAccommodationPrice(accommodation, basePrice, travellersTotalCount) {
	let total = basePrice;
	if (accommodation.accommodationItems) {
		// get room upgrade price
		const includedAccommodationItem = accommodation.accommodationItems[0];
		const upgradePrice = includedAccommodationItem.upgradePrice || 0;
		total = total + upgradePrice;

		// get board data
		// TODO delete .codes which is replaced by .boards
		const boards = includedAccommodationItem.boards || includedAccommodationItem.codes;
		let boardToDisplay = boards[0] || {};
		const boardUpgradePrice = boardToDisplay.upgradePrice || 0;
		total += boardUpgradePrice;

		total = Math.ceil(total / travellersTotalCount);
	}

	return total;
}

export function getPercentile(data, percentile) {
	let index = (percentile / 100) * data.length;
	let result;
	if (Math.floor(index) === index) {
		result = (data[index - 1] + data[index]) / 2;
	} else {
		result = data[Math.floor(index)];
	}
	return result;
}

export function round50(x) {
	return Math.round(x / 50) * 50;
}

export const mapOcupanciesToPassengers = (occupancies = []) => {
	let adults = 0;
	let children = 0;
	let childrenBirthdates = [];

	const passsengers = [];

	occupancies.forEach(occupancy => {
		adults += occupancy.adults;
		children += occupancy.children;
		childrenBirthdates = childrenBirthdates.concat(occupancy.childrenBirthdates);
	});

	range(0, adults).forEach(index => {
		passsengers.push({ type: PASSENGER_TYPE.ADULT, index: index + 1 });
	});

	range(0, children).forEach(index => {
		passsengers.push({
			type: PASSENGER_TYPE.CHILD,
			index: index + 1,
			birthdate: childrenBirthdates[index],
		});
	});

	return passsengers;
};

export const buildSDPSearchPayload = data => {
	const occupancies = data.occupancies.map(occupancy => {
		const childrenBirthdates =
			occupancy.childrenBirthdates &&
			occupancy.childrenBirthdates.map(birthdates => {
				return birthdates;
			});

		return {
			...occupancy,
			childrenBirthdates,
		};
	});

	// ex Paris : Fri Jun 11 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)
	let departureDate = data.departureDate;
	// => Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)
	departureDate = subHours(departureDate, departureDate.getTimezoneOffset() / 60);
	// departureDate.geTime() gives 11/06/2021
	// new Date(departureDate.geTime()) is stored in booking reducer abd used to initialize the form and gives Fri Jun 11 2021 02:00:00 GMT+0200 (heure d’été d’Europe centrale)

	const queryData = {
		departureCityCode: data.departureCity.code,
		departureCityLabel: data.departureCity.label,
		destinationResortCode: data.destinationResort.code,
		destinationResortLabel: data.destinationResort.label,
		cabinCode: data.cabin.code,
		cabinLabel: data.cabin.label,
		duration: data.duration.code,
		departureDate: departureDate.getTime(),
		occupancies: occupancies,
	};

	const queryParams = stringify(queryData, {
		encodeValuesOnly: true,
		arrayFormat: "indices",
	});

	sendTagOnSDPSearch(queryData);

	return queryParams;
};
